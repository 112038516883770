/**
 * keep in sync with StudyNodeVoter.php
 */
import { User } from '@/settings/types'
import { Study, StudyNode, StudyStep } from '@/store/modules/study/types'

import studyPermissions from './study'
import studyStepPermissions from './studyStep'
 
export default {
  canShow: (node: StudyNode, user: User, step: StudyStep, study: Study): boolean => {
    return studyPermissions.canShow(study, user)
  },
  canEdit: (node: StudyNode, user: User, step: StudyStep, study: Study): boolean => {
    return studyStepPermissions.canEdit(step, user, study)
  },
  canShowPatientList: (node: StudyNode, user: User, step: StudyStep, study: Study): boolean => {
    return studyPermissions.canShowPatientList(study, user)
  }
}
