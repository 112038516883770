<script>
import { SancareModal, SancareOcticon } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'

import { profileLabels } from '@/misc/permissions/permissionsHelper'

const DEFAULT_FORM_DATA = {
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  password: '',
  passwordConfirmation: '',
  role: 'ROLE_RESEARCHER',
  // teamId: -1,
  // teamName: null,
}

const DEFAULT_FORM_ERRORS = {
  passwordTooShort: false,
  passwordChars: false,
  passwordMismatch: false,
  missingUsername: false,
  invalidEmail: false,
}

export default {
  components: {
    'sancare-modal': SancareModal,
    'sancare-octicon': SancareOcticon,
  },
  props: {
    showEditor: { type: Boolean, required: true },
    editedUser: { type: Object, default: null },
  },
  emits: ['submit-form', 'close-modal'],
  data() {
    return {
      mailerDisable: window.mailerDisable,
      editPassword: false,
      formData: { ...DEFAULT_FORM_DATA },
      formErrors: { ...DEFAULT_FORM_ERRORS },
      requestError: null,
      profileLabels,
    }
  },
  computed: {
    ...mapState({
      forgottenPasswordRequest: (state) => state.login.forgottenPasswordRequest,
      availableRoles: (state) => state.settings.currentUser.editableRoles,
      updateUserRequest: (state) => state.admin.updateUserRequest,
      createUserRequest: (state) => state.admin.createUserRequest,
    }),
    ...mapGetters(['userRole']),
  },
  watch: {
    'formData.password': function() {
      if (this.formErrors.passwordTooShort || this.formErrors.passwordChars) {
        this.checkPassword()
      }
      if (this.formErrors.passwordMismatch) {
        this.checkPasswordConfirmation()
      }
    },
    'formData.username': function() {
      if (this.formErrors.missingUsername) {
        this.checkUsername()
      }
    },
    'formData.passwordConfirmation': function() {
      if (this.formErrors.passwordMismatch) {
        this.checkPasswordConfirmation()
      }
    },
    'formData.email': function() {
      if (this.formErrors.invalidEmail) {
        this.checkEmail()
      }
    },
    updateUserRequest(req) {
      if (req.fetching) {
        this.requestError = null
      } else if (req.error) {
        this.requestError = req.error
      }
    },
    createUserRequest(req) {
      if (req.fetching) {
        this.requestError = null
      } else if (req.error) {
        this.requestError = req.error
      }
    },
    showEditor(show) {
      if (!show) {
        this.formData = { ...DEFAULT_FORM_DATA }
        this.formErrors = { ...DEFAULT_FORM_ERRORS }
        this.editPassword = false
        this.$store.commit('forgottenPasswordRequestReset')
      }
    },
    editedUser(user) {
      if (user) {
        this.formData = { ...DEFAULT_FORM_DATA, ...user, password: '' }
      }
    },
  },
  methods: {
    checkPassword() {
      this.formErrors.passwordTooShort = this.formData.password.length < 8
      this.formErrors.passwordChars = !this.formData.password.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/)
      if (this.formData.passwordConfirmation) {
        this.formErrors.passwordMismatch = this.formData.password !== this.formData.passwordConfirmation
      }
    },
    checkPasswordConfirmation() {
      this.formErrors.passwordMismatch = this.formData.password !== this.formData.passwordConfirmation
    },
    checkUsername() {
      this.formErrors.missingUsername = this.formData.username.length === 0
    },
    checkEmail() {
      const err = this.formData.email.length === 0 || !this.formData.email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
      this.formErrors.invalidEmail = err
    },
    resetPassword() {
      this.$store.dispatch('forgottenPassword', { username: this.formData.username })
    },
    handleSubmit() {
      // We check the password and confirmation if:
      //  - We create a new user, and the mailer is disabled
      //  - We edit an existing user and we chose to edit the password
      if ((!this.editedUser && this.mailerDisable) || (this.editedUser && this.editPassword)) {
        this.checkPassword()
        this.checkPasswordConfirmation()
      }
      this.checkUsername()
      this.checkEmail()

      if (_.some(this.formErrors)) {
        return
      }

      const submittedData = {}
      if (this.editedUser) {
        submittedData.id = this.editedUser.id
        _.forEach(['firstName', 'lastName', 'email', 'username', 'role'], (prop) => {
          if (this.formData[prop] !== this.editedUser[prop]) {
            submittedData[prop] = this.formData[prop]
          }
        })
        if (this.editPassword) {
          submittedData.password = this.formData.password
        }
      } else {
        _.forEach(['firstName', 'lastName', 'email', 'username', 'role'], (prop) => submittedData[prop] = this.formData[prop])
        if (this.mailerDisable) {
          submittedData.password = this.formData.password
        }
      }

      this.$emit('submit-form', submittedData)
    },
    getEditableRoles() {
      // Exclude ROLE_DATA_PROVIDER from editable roles since it should only be available in backend
      return _.intersection(_.keys(this.profileLabels), this.availableRoles)
    }
  }
}
</script>

<template>
  <sancare-modal
    :open="showEditor"
    @close-modal="$emit('close-modal');"
  >
    <template #header>
      <div
        class="row"
      >
        <div class="sancare-modal-title col">
          {{ editedUser ? "Éditer un utilisateur" : "Nouvel Utilisateur" }}
        </div>
        <div
          class="sancare-modal-close col-auto"
          @click="$emit('close-modal');"
        >
          <sancare-octicon
            name="x"
            :width="20"
            :height="20"
          />
        </div>
      </div>
    </template>
    <form
      class="row"
      @submit.prevent="handleSubmit"
    >
      <h3 class="col-12 title-light">
        Informations générales
      </h3>
      <div class="form-group col-12 col-md-6">
        <label
          class="small-label"
          for="firstName"
        >
          Prénom
        </label>
        <input
          id="firstName"
          v-model="formData.firstName"
          class="form-control"
        >
      </div>
      <div class="form-group col-12 col-md-6">
        <label
          class="small-label"
          for="lastName"
        >
          Nom
        </label>
        <input
          id="lastName"
          v-model="formData.lastName"
          class="form-control"
        >
      </div>
      <div class="form-group col-12 col-md-6">
        <label
          class="small-label"
          for="username"
        >
          Identifiant
        </label>
        <input
          id="username"
          v-model="formData.username"
          :class="{ 'is-invalid': formErrors.missingUsername }"
          :disabled="editedUser !== null"
          class="form-control"
          @blur="checkUsername"
        >
      </div>
      <div class="form-group col-12 col-md-6">
        <label
          class="small-label"
          for="email"
        >
          Email
        </label>
        <input
          id="email"
          v-model="formData.email"
          :class="{ 'is-invalid': formErrors.invalidEmail }"
          :disabled="editedUser !== null"
          class="form-control"
          @blur="checkEmail"
        >
      </div>
      <div
        v-if="(!editedUser && mailerDisable) || (editedUser && !editedUser.createdFromLdap)"
        class="form-group col-12"
      >
        <h3 class="title-light">
          Mot de passe
        </h3>
        <div v-if="editedUser && !editPassword">
          <div v-if="!mailerDisable && !forgottenPasswordRequest.ok && !forgottenPasswordRequest.error">
            <button
              class="btn btn-link"
              type="button"
              @click="resetPassword"
            >
              Envoyer un mail de réinitialisation
            </button>
          </div>
          <div
            v-else-if="forgottenPasswordRequest.ok"
            class="text-success"
          >
            Un email de réinitialisation à été envoyé à l'adresse de l'utilisateur
          </div>
          <div
            v-else-if="forgottenPasswordRequest.error"
            class="text-danger"
          >
            {{ forgottenPasswordRequest.error }}
          </div>
          <div>
            <button
              class="btn btn-link"
              type="button"
              @click="editPassword = true"
            >
              Modifier le mot de passe
            </button>
          </div>
        </div>
        <div
          v-if="(!editedUser && mailerDisable) || editPassword"
          class="row"
        >
          <div class="col-12 col-md-6">
            <div>
              <label
                class="small-label"
                for="password"
              >
                Mot de passe
              </label>
              <input
                id="password"
                v-model="formData.password"
                :class="{ 'is-invalid': formErrors.passwordTooShort || formErrors.passwordChars }"
                class="form-control"
                type="password"
                @blur="checkPassword"
              >
              <div class="invalid-feedback">
                <div v-if="formErrors.passwordTooShort">
                  Ce mot de passe est trop court.
                </div>
                <div v-if="formErrors.passwordChars">
                  Le mot de passe doit contenir au moins une minuscule, une majuscule et un chiffre.
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <label
              class="small-label"
              for="passwordConfirmation"
            >
              Confirmation du mot de passe
            </label>
            <input
              id="passwordConfirmation"
              v-model="formData.passwordConfirmation"
              :class="{ 'is-invalid': formErrors.passwordMismatch }"
              class="form-control"
              type="password"
              @blur="checkPasswordConfirmation"
            >
            <div class="invalid-feedback">
              <div v-if="formErrors.passwordMismatch">
                Les mots de passe sont différents.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 row">
        <h3 class="col-12 title-light">
          Permissions
        </h3>
        <div class="form-group col-12 col-md-6">
          <label
            class="small-label"
            for="role"
          >
            Type de compte
          </label>
          <select
            id="role"
            v-model="formData.role"
            :disabled="editedUser && editedUser.sancareUser"
            class="form-control"
          >
            <option
              v-for="role in getEditableRoles()"
              :key="role"
              :value="role"
            >
              {{ profileLabels[role] }}
            </option>
          </select>
        </div>
      </div>
      <!-- TODO: Define and manage teams
      <div class="form-group col-12 col-md-6">
        <label
          class="small-label"
          for="role"
        >
          Equipe
        </label>
        <span v-if="teamInput === 'select'">
          <select
            id="team"
            v-model="formData.teamId"
            class="form-control"
          >
            <option
              :value="-1"
              @click="teamInput = 'free'"
            >
              nouvelle equipe
            </option>
            <option
              v-for="team in teamList"
              :value="team.id"
              :key="'team_'+team.id"
            >
              {{ team.name }}
            </option>
          </select>
        </span>
        <span
          v-if="teamInput === 'free'"
          class="row mx-1"
        >
          <input
            v-model="formData.teamName"
            class="col form-control"
          >
          <span
            class="col-auto"
            @click="teamInput = 'select'"
          >
            X
          </span>
        </span>
      </div>
      -->
      <div class="col-12 row align-items-center">
        <div class="col">
          <div
            v-if="requestError"
            class="text-danger"
          >
            {{ requestError }}
          </div>
        </div>
        <div class="col-auto">
          <button class="btn btn-push btn-primary btn-padded">
            Valider
          </button>
        </div>
      </div>
    </form>
  </sancare-modal>
</template>
