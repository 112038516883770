import { OperatorEnum } from '@/store/modules/search/types'
import { Study, StudyNode, StudyPrivacy } from '@/store/modules/study/types'
import { studyNodeIdPrefix, uniqueId } from '@/study/utils'

/**
 * Function return to avoid accidental assignment
 */
export function emptyStudy(): Study {
  return {
    id: null,
    reference: '',
    user: null,
    name: uniqueId('Nouvelle étude '),
    privacy: StudyPrivacy.PRIVATE_STUDY,
    validatedAt: null,
    steps: [],
    variables: [],
    studyUsers: [],
    disabled: false,
  }
}

export function emptyStudyNode(isRoot = false): StudyNode {
  return {
    id: uniqueId(studyNodeIdPrefix),
    name: null,
    level: isRoot ? 1 : null,
    operator: isRoot ? OperatorEnum.AND : OperatorEnum.OR,
    diagramProperties: { x: 0, y: 0 },
    children: [],
    conditionList: []
  }
}
