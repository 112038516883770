/**
 * keep in sync with CriterionVoter.php
 */
import { hasPermission, RoleEnum } from '@/misc/permissions/permissionsHelper'
import { User } from '@/settings/types'
import { Criterion } from '@/store/modules/search/types'

function isOwner(criterion: Criterion, user: User): boolean {
  return criterion.user.id === user.id
}

function canCreate(): boolean {
  return true
}
function canEdit(criterion: Criterion, user: User): boolean {
  return hasPermission(RoleEnum.ROLE_STUDY_ADMIN, user.role)
        || isOwner(criterion, user)
}
function canShow(): boolean {
  return true
}

export default {
  canCopy: (): boolean => {
    return canCreate() && canShow()
  },
  canCreate,
  canDelete: (criterion: Criterion, user: User): boolean => {
    return canEdit(criterion, user)
  },
  canEdit,
  canShow,
}
