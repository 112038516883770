import * as joint from 'jointjs'

const linkColor = '#383a64'

export const Link = joint.shapes.standard.Link.define('sancare.Link', {
  attrs: {
    line: {
      stroke: linkColor,
      targetMarker: {
        type: 'none',
      }
    }
  },
  markup: [{
    tagName: 'path',
    selector: 'wrapper',
    attributes: {
      fill: 'none',
    }
  }, {
    tagName: 'path',
    selector: 'line',
    attributes: {
      fill: 'none',
    }
  }]
})

export const Step = joint.shapes.standard.Rectangle.define('sancare.Step', {
  attrs: {
    body: {
      fill: 'transparent',
      rx: 5,
      ry: 5,
      strokeWidth: 0,
    }
  },
  markup: [{
    tagName: 'rect',
    selector: 'body',
    attributes: {
      cursor: 'move',
    }
  }]
})

export const StepLink = joint.shapes.standard.Link.define('sancare.StepLink', {
  attrs: {
    line: {
      stroke: linkColor,
    }
  },
  markup: [{
    tagName: 'path',
    selector: 'wrapper',
    attributes: {
      fill: 'none',
    }
  }, {
    tagName: 'path',
    selector: 'line',
    attributes: {
      fill: 'none',
    }
  }]
})
export const StudyNode = joint.shapes.standard.Rectangle.define('sancare.StudyNode', {
  attrs: {
    body: {
      fill: 'transparent',
      rx: 5,
      ry: 5,
      strokeWidth: 0,
    }
  },
  markup: [{
    tagName: 'rect',
    selector: 'body',
    attributes: {
      cursor: 'move',
    }
  }]
})

export const Search = joint.shapes.standard.Rectangle.define('sancare.Search', {
  attrs: {
    body: {
      fill: 'transparent',
      rx: 5,
      ry: 5,
      strokeWidth: 0,
    }
  },
  markup: [{
    tagName: 'rect',
    selector: 'body',
    attributes: {
      cursor: 'move',
    }
  }]
})
