<script>

export default {
  props: {
    filterData: { type: Object, required: true },
    isAdvancedSearch: { type: Boolean, required: true }
  },
  emits: ['add-filter'],
  data() {
    const subFiltersValues = {}

    if (this.filterData.subFilters) {
      this.filterData.subFilters.forEach((filter) => {
        if (filter.default) {
          subFiltersValues[filter.name] = filter.default
        } else {
          subFiltersValues[filter.name] = filter.options ? filter.options[0] : ''
        }
      })
    }

    return {
      subFiltersValues: subFiltersValues,
      value: this.filterData.default || '',
    }
  },
  methods: {
    addFilter() {
      if (!this.value || !this.value.trim())
        return 

      const presence = 'present'
      const name = this.filterData.name
      let type = this.filterData.name
      type = type.charAt(0).toUpperCase() + type.slice(1)
      type = `${presence}${type}`
      const value = this.value
      
      this.$emit('add-filter', { name, type, value })
    },
  },
}
</script>

<template>
  <div class="row">
    <div
      :class="{
        'col-4': !isAdvancedSearch,
        'col-12': isAdvancedSearch
      }"
      class="input-group"
    >
      <select
        v-for="filter in filterData.subFilters"
        :key="filter.name"
        v-model="subFiltersValues[filter.name]"
        class="custom-select"
      >
        <option
          v-for="opt in filter.options"
          :key="opt"
          :value="opt"
        >
          {{ filter.labels[opt] ? filter.labels[opt] : opt }}
        </option>
      </select>
      <input
        v-model="value"
        name="presentTitleCriteria_input"
        class="form-control col p-1"
        @keyup.enter="addFilter"
      >
      <div class="input-group-append">
        <button
          class="btn btn-primary"
          type="button"
          @click="addFilter"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
</template>
