<template>
  <div class="sancare-logo large-container">
    <div class="row align-items-center">
      <router-link
        name="stayFinder_home"
        :to="{ path: '/' }"
        class="logo row no-gutters align-items-center"
      >
        <div class="col-auto">
          <img
            alt="Logo Sancare"
            src="../assets/sancare_logo.png"
          >
        </div>
        <div class="col-auto pl-3">
          <div class="logo-title">
            SANCARE
          </div>
          <div class="logo-subtitle">
            HEALTH &amp; DATA
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
