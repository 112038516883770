/**
 * keep in sync with UserVoter.php
 */
import { User } from '@/settings/types'

import { hasPermission, RoleEnum } from './permissionsHelper'
 
export default {
  canList: (currentUser: User): boolean => {
    return hasPermission(RoleEnum.ROLE_USER_VIEWER, currentUser.role)
  },
  canCreate: (currentUser: User): boolean => {
    return hasPermission(RoleEnum.ROLE_USER_MANAGER, currentUser.role)
  },
  canDelete: (user: User, currentUser: User): boolean => {
    return hasPermission(RoleEnum.ROLE_USER_MANAGER, currentUser.role)
      && user.id !== currentUser.id
      && user.editableRoles.includes(user.role)
  },
  canEdit: (user: User, currentUser: User): boolean => {
    return user.id === currentUser.id
      || (
        hasPermission(RoleEnum.ROLE_USER_MANAGER, currentUser.role)
        && currentUser.editableRoles.includes(user.role)
      )
  },
  canEnable: (user: User, currentUser: User): boolean => {
    return hasPermission(RoleEnum.ROLE_USER_MANAGER, currentUser.role)
      && user.disabled === true
      && currentUser.editableRoles.includes(user.role)
  }
}
