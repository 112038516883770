/**
 * keep in sync with StudyStepVoter.php
 */
import { User } from '@/settings/types'
import { Study, StudyStep } from '@/store/modules/study/types'

import studyPermissions from './study'

export default {
  canEdit: (step: StudyStep, user: User, study: Study): boolean => {
    if (step.isCore) {
      return studyPermissions.canEditCoreStep(study, user)
    }
    return studyPermissions.canEditSteps(study, user)
  }
}
