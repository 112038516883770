<script>
import { DatePicker } from '@sancare/ui-frontend-commons'
import { mapState } from 'vuex'

export default {
  components: {
    'date-picker': DatePicker,
  },
  props: {
    showDatepickers: { type: Boolean, default: true },
  },
  emits: ['refresh-list', 'set-list-param'],
  data() {
    return {
      pageOptions: [
        { text: '10', value: 10 },
        { text: '20', value: 20 },
        { text: '30', value: 30 },
        { text: '50', value: 50 },
        { text: '80', value: 80 },
        { text: '100', value: 100 },
      ],
    }
  },
  computed: {
    ...mapState({
      params: (state) => state.patientList.patientListParams,
      earlyLimit: (state) => state.settings.earlyLimit,
      lateLimit: (state) => state.settings.lateLimit,
    }),
  },
  methods: {
    setPageSize(value) {
      this.$emit('set-list-param', 'pageSize', value)
      this.$emit('set-list-param', 'currentPage', 1)
      this.$emit('refresh-list', { resetStaySelection: false, refreshUrl: true })
    },
    setEarlylimit(limit) {
      this.$store.commit('setEarlyLimit', limit)
      this.$emit('refresh-list', { resetStaySelection: true, refreshUrl: true })
    },
    setLateLimit(limit) {
      this.$store.commit('setLateLimit', limit)
      this.$emit('refresh-list', { resetStaySelection: true, refreshUrl: true })
    },
  }
}
</script>

<template>
  <div class="list-parameters">
    <div class="row px-3 py-3 align-items-end">
      <h2>Paramètres</h2>
    </div>
    <div class="row align-items-center">
      <div
        v-if="showDatepickers"
        class="col-md-4 col-4"
      >
        <div>
          <div class="row px-2 py-2 align-items-center">
            <div class="col-auto px-2">
              Période du
            </div>
            <div class="col-auto px-2">
              <date-picker
                :model-value="earlyLimit"
                @update:model-value="setEarlylimit"
              />
            </div>
            <div class="col-auto px-1">
              au
            </div>
            <div class="col-auto px-2">
              <date-picker
                :model-value="lateLimit"
                @update:model-value="setLateLimit"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-4">
        <div class="row px-2 align-items-center">
          <div class="col-auto px-2 ">
            Afficher
          </div>
          <div class="col-3 px-1">
            <select
              :value="params.pageSize"
              class="form-control"
              @change="(e) => setPageSize(e.target.value)"
            >
              <option
                v-for="option in pageOptions"
                :key="'page-option-'+option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
          <div class="col px-1">
            résultats par page
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
