import Api from '@sancare/ui-frontend-commons/src/misc/api'

import { state } from '@/store/modules/snomed/state'

export const actions = {
  conceptCompletion({ commit }, term: string) {
    return Api.request(state.requests.conceptCompletion, {
      urlParams: { term },
      successMut: 'conceptCompletionSuccess',
    }, commit)
  },
}
