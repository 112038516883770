<script>
import { InputAutoCompletion, SancareOcticon } from '@sancare/ui-frontend-commons'
import { useAutoCompletionDebouncedWatcher } from '@sancare/ui-frontend-commons/src/misc/composables/useAutoCompletionDebouncedWatcher'
import { ref, watchEffect } from 'vue'

export default {
  components: {
    'sancare-octicon': SancareOcticon,
    'input-auto-completion': InputAutoCompletion,
  },
  props: {
    type: { type: String, required: true },
    userId: { type: Number, required: true },
    userFinessList: { type: Array, required: true },
    completionFinessList: { type: Array, required: true },
  },
  data() {
    return {
      showCompletion: false,
      finessInput: '',
      requestError: null,
      label: this.type === 'legal' ? 'FINESS juridique' : 'FINESS géographique',
      assignFinessRequestName: this.type === 'legal' ? 'assignLegalFiness' : 'assignGeoFiness',
      unassignFinessRequestName: this.type === 'legal' ? 'unassignLegalFiness' : 'unassignGeoFiness',
      searchFinessRequestName: this.type === 'legal' ? 'searchLegalFinessRequest' : 'searchGeoFinessRequest',
      clearFinessListRequestName: this.type === 'legal' ? 'searchLegalFinessRequestClear' : 'searchGeoFinessRequestClear',
    }
  },
  computed: {
    assignFinessRequest() {
      return this.type === 'legal' ? this.$store.state.admin.assignLegalFinessRequest : this.$store.state.admin.assignGeoFinessRequest
    },
    unassignFinessRequest() {
      return this.type === 'legal' ? this.$store.state.admin.unassignLegalFinessRequest : this.$store.state.admin.unassignGeoFinessRequest
    },
    searchFinessRequest() {
      return this.type === 'legal' ? this.$store.state.admin.searchLegalFinessRequest : this.$store.state.admin.searchGeoFinessRequest
    },
  },
  watch: {
    assignFinessRequest(req) {
      this.requestError = req.error
    },
    unassignFinessRequest(req) {
      this.requestError = req.error
    }
  },
  mounted() {
    const finessInputRef = ref(this.finessInput)
    const completionFinessListRef = ref(this.completionFinessList)

    useAutoCompletionDebouncedWatcher(
      finessInputRef,
      completionFinessListRef,
      (search, signal) => this.fetchFinessData(search, signal),
      () => this.clearFinessData()
    )

    watchEffect(() => {
      finessInputRef.value = this.finessInput
    })
  },
  methods: {
    assignFiness(finess) {
      if (finess) {
        this.$store.dispatch(this.assignFinessRequestName, { userId: this.userId, finess })
        this.finessInput = null
      }
    },
    unassignFiness(finess) {
      this.$store.dispatch(this.unassignFinessRequestName, { userId: this.userId, finess })
    },
    openCompletion() {
      this.showCompletion = true
    },
    closeCompletion() {
      this.showCompletion = false
    },
    selectFiness(completionItem) {
      this.finessInput = completionItem.reference
      this.showCompletion = false
    },
    fetchFinessData(search, signal) {
      this.$store.dispatch(this.searchFinessRequestName, { search, signal })
    },
    clearFinessData() {
      this.$store.commit(this.clearFinessListRequestName)
    },
  },
}
</script>

<template>
  <div class="settings-table__data-row">
    <div class="col input-group row no-gutters">
      <label
        class="form-label col-6"
        :for="'finness'+type"
      >
        {{ label }}
      </label>
      <div>
        <input
          :id="'finness'+type"
          v-model="finessInput"
          v-on-click-outside="closeCompletion"
          class="form-control"
          type="text"
          @click="openCompletion"
          @keydown.esc="closeCompletion"
          @keyup.enter="assignFiness(finessInput)"
        >
        <input-auto-completion
          v-show="showCompletion && finessInput !== ''"
          :completion-list="completionFinessList"
          :is-loading="searchFinessRequest.fetching"
          :title-prop="'reference'"
          :description-prop="'label'"
          @select-item="selectFiness"
        />
      </div>
      <div class="col input-group-append">
        <button
          class="col-auto btn btn-primary"
          type="button"
          @click="assignFiness(finessInput)"
        >
          Ok
        </button>
      </div>
    </div>

    <div class="col">
      <div
        v-if="requestError"
        class="text-danger"
      >
        {{ requestError }}
      </div>
    </div>

    <div
      v-for="finess in userFinessList"
      :key="finess['finess']"
      class="badge-primary badge col-auto mx-1 mb-1 pl-2 pr-2"
    >
      <span>{{ finess['shortDescription'] }} ({{ finess['finess'] }})</span>
      <span
        @click="unassignFiness(finess['finess'])"
      >
        <sancare-octicon
          name="x"
          :width="14"
          :height="11"
        />
      </span>
    </div>
  </div>
</template>
