import { setRequest } from '@sancare/ui-frontend-commons/src/misc/api'

import { Investigator, StudyState } from '@/store/modules/study/types'

export const mutations = {
  setInvestigators(state: StudyState, response: Investigator[]) {
    state.investigators = response
  },
  setRequest
}
