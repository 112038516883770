import { setRequest } from '@sancare/ui-frontend-commons/src/misc/api'
import { DrugEventPrestationType, HealthDataType } from '@sancare/ui-frontend-commons/src/types/health-data'

import { getStayDocPropertyFromDocType } from '@/patient/health-data/utils'
import { HealthData } from '@/store/modules/health-data/types'
import { Stay } from '@/store/modules/stay/types'
import { StudyNodeMatches } from '@/text-processing/types'

import { Patient, PatientListParams, PatientState } from './types'

interface DocumentMutPayload {
  document: HealthData
  documentType: HealthDataType
  drugEventPrestationType?: DrugEventPrestationType|null
}
interface GetOnePatientResponse {
  matchingStayIds: number[]
  patient: Patient
}

export const mutations = {
  setRequest,
  getOnePatientSuccess(state: PatientState, response: GetOnePatientResponse) {
    state.currentPatient = response.patient
    state.matchingStayIds = response.matchingStayIds
    // TODO: Might format data to match with displayable types (i.e. dates)
    state.currentStay = response.patient.stays[0] ?? null
    state.currentDocument = response.patient.stays[0]?.reports[0] ?? null
    state.currentDocumentType = response.patient.stays[0]?.reports[0] ? HealthDataType.REPORT : null
  },

  // refresh current stay when we loose the reference (after text-worker replaces the whole patient for ex)
  refreshCurrentStay(state: PatientState) {
    if(!state.currentStay) {
      return
    }
    const currentStay = state.currentPatient.stays.find((s) => s.id === state.currentStay.id)
    if(currentStay) {
      state.currentStay = currentStay
    }
  },
  // refresh current doc when we loose the reference (after text-worker replaces the whole patient for ex)
  refreshCurrentDocument(state: PatientState) {
    if(!state.currentDocument || !state.currentDocumentType || !state.currentStay) {
      return
    }
    let currentDocument = null
    const documentProperty = getStayDocPropertyFromDocType(state.currentDocumentType)
    currentDocument = state.currentStay[documentProperty].find((d) => d.id === state.currentDocument.id)
    if (currentDocument) {
      state.currentDocument = currentDocument
      return
    }
  },
  resetPatientState(state: PatientState) {
    state.currentPatient = null
    state.matchingStayIds = null
    state.currentStay = null
    state.currentDocument = null
    state.currentDocumentType = null
  },
  setCurrentDocument(state: PatientState, docPayload: DocumentMutPayload) {
    state.currentDocument = docPayload.document
    state.currentDocumentType = docPayload.documentType
    state.currentDrugEventPrestationType = docPayload.drugEventPrestationType ?? null
  },
  setCurrentPatient(state: PatientState, patient: Patient) {
    state.currentPatient = patient
  },
  setPatientListParams(state: PatientState, patientListParams: PatientListParams) {
    sessionStorage.setItem('patientListParams', JSON.stringify(patientListParams))
    state.patientListParams = patientListParams
  },
  setCurrentStay(state: PatientState, stay: Stay) {
    // TODO: Might format data to match with displayable types (i.e. dates)
    state.currentStay = stay
  },
  setStudyNodeMatches(state: PatientState, studyNodeMatches: StudyNodeMatches) {
    state.studyNodeMatches = studyNodeMatches
  },
  setVariableMatches(state: PatientState, variableMatches: StudyNodeMatches) {
    state.variableMatches = variableMatches
  },
  setTextWorkerReady(state: PatientState, isReady: boolean) {
    state.textWorkerReady = isReady
  },
}
