import Api from '@sancare/ui-frontend-commons/src/misc/api'

import { state } from './state'

export const actions = {
  createStudyFromFile({ commit }, file) {
    return Api.request(state.requests.createStudyFromFile, {
      files: { studyJsonFile: file },
      startMut: 'createStudyFromFileStart',
      successMut: 'createStudyFromFileSuccess',
      errorMut: 'createStudyFromFileError',
    }, commit)
  },
  fetchInvestigators({ commit }) {
    return Api.request(state.requests.fetchInvestigators, {
      successMut: 'setInvestigators',
    }, commit)
  }
}
