import { ExpiredPasswordForm, LoginForm, UnloggedPage } from '@sancare/ui-frontend-commons'
import _ from 'lodash'
import { createRouter, createWebHistory } from 'vue-router'
import { sync } from 'vuex-router-sync/dist/vuex-router-sync.esm-bundler'

import AdminPage from '@/admin/AdminPage.vue'
import JobsPage from '@/job/JobsPage.vue'
import { hasPermission } from '@/misc/permissions/permissionsHelper'

import CriterionEditorPage from './criteria/CriterionEditorPage.vue'
import CriteriaListManager from './home/CriteriaListManager.vue'
import HomePage from './home/HomePage.vue'
import VariableListManager from './home/VariableListManager.vue'
import ResetPasswordForm from './login/ResetPasswordForm.vue'
import NotFoundComponent from './misc/NotFoundComponent'
import PatientPage from './patient/PatientPage'
import PatientListPage from './patient-list/PatientListPage.vue'
import store from './store'
import StudyNodeEditorPage from './study/StudyNodeEditorPage.vue'
import StudyPage from './study/StudyPage'
import StudyVariableManager from './study/StudyVariableManager.vue'
import VariableEditorPage from './variables/VariableEditorPage.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      component: UnloggedPage,
      children: [
        {
          path: '',
          component: LoginForm,
          meta: { requiresAuth: false, unloggedPage: true },
        },
        {
          // This route is used in the emails sent by the backend,
          // avoid changing it or it will cause lots of problems
          path: 'reset-password/:link',
          component: ResetPasswordForm,
          meta: { requiresAuth: false, unloggedPage: true, requiresMailer: true },
        },
        {
          // This route is used by users to change their password when it is expired
          path: 'expired-password',
          component: ExpiredPasswordForm,
          meta: { requiresAuth: false, unloggedPage: true },
        },
      ],
    },
    {
      name: 'home',
      path: '/',
      component: HomePage,
      meta: { requiresAuth: true }
    },
    {
      name: 'patientList',
      path: '/patient-list',
      component: PatientListPage,
      meta: { requiresAuth: true }
    },
    {
      name: 'patient',
      path: '/patient/:patientIpp',
      component: PatientPage,
      meta: { requiresAuth: true }
    },
    {
      name: 'study',
      path: '/study/:studyId',
      component: StudyPage,
      meta: { requiresAuth: true }
    },
    {
      name: 'jobs',
      path: '/jobs/:studyId?',
      component: JobsPage,
      props: (route) => ({ studyId: route.params.studyId ? Number(route.params.studyId) : null }),
      meta: { requiresAuth: true }
    },
    {
      name: 'criteriaManager',
      path: '/criteria-manager',
      component: CriteriaListManager,
      meta: { requiresAuth: true }
    },
    {
      name: 'variableManager',
      path: '/variable-manager',
      component: VariableListManager,
      meta: { requiresAuth: true }
    },
    {
      name: 'variableNew',
      path: '/variable/new',
      component: VariableEditorPage,
      meta: { requiresAuth: true }
    },
    {
      name: 'variable',
      path: '/variable/:variableId',
      component: VariableEditorPage,
      meta: { requiresAuth: true }
    },
    {
      name: 'criterionNew',
      path: '/criterion/new',
      component: CriterionEditorPage,
      meta: { requiresAuth: true }
    },
    {
      name: 'criterion',
      path: '/criterion/:criterionId',
      component: CriterionEditorPage,
      meta: { requiresAuth: true }
    },
    {
      name: 'studyNodeNew',
      path: '/study-node/new',
      component: StudyNodeEditorPage,
      meta: { requiresAuth: true }
    },
    {
      name: 'studyNodeEdit',
      path: '/study-node/:studyNodeId',
      component: StudyNodeEditorPage,
      meta: {
        requiresAuth: true,
        requiresQueryParams: ['studyId', 'stepId']
      }
    },
    {
      name: 'studyVariable',
      path: '/study-variable/:studyId',
      component: StudyVariableManager,
      meta: { requiresAuth: true }
    },
    {
      path: '/admin',
      component: AdminPage,
      meta: { requiresAuth: true, requireSomeRoles: ['ROLE_USER_MANAGER', 'ROLE_ADMIN_MANAGER', 'ROLE_SETTING_UPDATER'] },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'notFound',
      component: NotFoundComponent,
    },
  ],
})

router.beforeEach((to, from, next) => {
  store.commit('toast/resetToast')

  // Pages forbidden to logged-in users
  if (to.matched.some((record) => record.meta.unloggedPage) && store.getters.isAuthenticated) {
    return next({ path: to.query.redirect || '/' })
  }

  // Pages forbidden if the mailer is disabled
  if (window.mailerDisable) {
    if (to.matched.some((record) => record.meta.requiresMailer)) {
      return next({ path: '/' })
    }
  }

  // Pages forbidden to unlogged users
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      const nextPage = { path: '/login' }
      if (to.fullPath !== '/') {
        nextPage.query = { redirect: to.fullPath }
      }
      return next(nextPage)
    }
  }

  // Pages forbidden to non-admins
  let permissionDenied = false
  _.forEach(to.matched, (route) => {
    if (route.meta.requireSomeRoles) {
      permissionDenied = permissionDenied || _.every(route.meta.requireSomeRoles, (role) => !hasPermission(role, store.getters.userRole))
    }
    if (route.meta.requireAllRoles) {
      permissionDenied = permissionDenied || _.some(route.meta.requireSomeRoles, (role) => !hasPermission(role, store.getters.userRole))
    }
  })
  if (permissionDenied) {
    return next({ path: '/' })
  }

  // Pages forbidden if required query parameters are missing
  if ('requiresQueryParams' in to.meta
    && to.meta.requiresQueryParams.some((param) => !(param in to.query) || to.query[param] === null)) {
    return next(new Error('URL invalide'))
  }

  return next()
})

router.onError(async (error) => {
  await router.push({ path: '/' })
  store.commit('toast/setError', error.message)
})

export function useRouter(callSync) {
  // FIXME awful hoot in order to prevent calling sync() in tests
  if (callSync) {
    sync(store, router)
  }

  return router
}

export default router
