import 'bootstrap/dist/css/bootstrap.min.css'
import 'vue-slider-component/theme/default.css'
import 'url-polyfill'
import './assets/styles/index.less'

import clickOutsideDirective from '@sancare/ui-frontend-commons/src/misc/click-outside'
import FloatingVue from 'floating-vue'
import { createApp } from 'vue'

import { useRouter } from './router'
import SancareApp from './SancareApp.vue'
import { useStore } from './store'

const app = createApp(SancareApp)
app.use(useStore())
app.use(useRouter(true))
app.use(FloatingVue)
app.directive('on-click-outside', clickOutsideDirective)
app.mount('#sancare-app')
