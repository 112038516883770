import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import { Constant } from '@sancare/ui-frontend-commons/src/store/modules/filters/medical-unit-filter/types'
import _ from 'lodash'

import filtersData, { AggregationFunctionEnum } from '@/filters/FiltersData'
import { documentCategories, getDocumentCategoryLabel, textualHealthDataCategories } from '@/stay-displayer/health-data/documentLabels.js'
import { StudyStep } from '@/store/modules/study/types'
import { ConditionModifiers, Modifiers } from '@/study/types'
import { AggregationModifiers, Variable } from '@/variables/types'

import { aggregationFunctionList } from './FiltersData'
import filtersLabels from './FiltersLabels'

function getCriteriaLabel(rawCriteriaType: string): string {
  const splitCriteriaType = rawCriteriaType.split('__')
  const conditionType = splitCriteriaType[0]
  const textualReportCategories = { ...documentCategories, ...textualHealthDataCategories }
  if(splitCriteriaType.length > 1){
    return `${filtersLabels[conditionType]} (${textualReportCategories[splitCriteriaType[1]].toLowerCase()})`
  }
  return filtersLabels[conditionType]
}

function getConstantTitle(healthConstantsList: Constant[], constantId: string): string {
  const constant = healthConstantsList.find((c) => c.id === Number(constantId))
  return constant ? constant.description : ''
}

function getAggregationModifiersLabel(modifiers: AggregationModifiers): string {
  if (!modifiers) {
    return ''
  }
  if (modifiers.quantile) {
    return `[${modifiers.quantile}]`
  }
  return ''
}
function getAggregationLabel(aggregation: string|null, modifiers: AggregationModifiers = null): string {
  if (aggregation === AggregationFunctionEnum.COUNT_STAY) {
    return aggregationFunctionList[aggregation].label
  }
  return aggregation ? `${aggregationFunctionList[aggregation].label}${getAggregationModifiersLabel(modifiers)}` : null
}
export function getConditionLabel(conditionMod: ConditionModifiers): string {
  if (conditionMod.aggregation) {
    if (conditionMod.label) {
      return `(${conditionMod.aggregation}) ${conditionMod.label}`
    }
    return conditionMod.aggregation
  }
  return conditionMod.label
}

function formatDate(date: string|number): string {
  return dayjs(date).format('L')
}

function renderCriteria(
  rawType: string,
  value: string,
  stepNames: StudyStep[],
  healthConstantsList: Constant[],
  variablesList: Variable[]
): string {
  if (!value) {
    switch (rawType) {
      case 'stay':
        return 'Nombre de séjours'
      case 'stayDate':
        return filtersLabels.fromStayDate
      case 'stayDuration':
        return filtersLabels.stayDuration
      default:
        return ''
    }
  }
  const type = rawType.indexOf('__') === -1 ? rawType : rawType.split('__')[0]
  let arr = value.split('_')
  let step, variable

  switch (type) {
    case 'rumCount':
      return `${arr[0]} à ${arr[1]}`

    case 'presentContent':
    case 'absentContent':
    // If there is a \x1F separator between the modifiers and the text, we show what's after the separator
    // If there is no \x1F, then the substring will start from 0, effectively doing nothing.
      return value.substring(value.indexOf('\x1F') + 1)

    case 'stayDuration':
    // We replace 0 by null, to avoid having a label like "0j to 2j" but rather "<2j"
      return makeRangeLabel(arr[0] === '0' ? null : arr[0], arr[1], 'j')

    case 'patientAge':
      return makeRangeLabel(arr[0] === '0' ? null : arr[0], arr[1], ' ans')

    case 'healthConstant':
      arr = value.split('\x1F')
      return `${getConstantTitle(healthConstantsList, arr[0])} ${makeRangeLabel(arr[1], arr[2])}`

    case 'documentCategory':
      return getDocumentCategoryLabel(value)

    case 'biologyResult':
      arr = value.split('\x1F')
      if (arr.length === 3) {
        return `${arr[0]} ${makeRangeLabel(arr[1], arr[2])}`
      }

      return `${arr[0]} à ${arr[1]}`

    case 'compareDiagnosis':
    case 'gender':
    case 'hasSession':
    case 'isDeceased':
    case 'releaseMode':
    case 'admissionMode':
    case 'origin':
    case 'destination':
      return filtersData[rawType]['labels'][value]

    case 'fromStayDate':
      return `Séjours à partir du ${formatDate(value)}`
    case 'fromDeceaseDate':
      return `Date de décès à partir du ${formatDate(value)}`

    case 'toStayDate':
      return `Séjours jusqu'au ${formatDate(value)}`
    case 'toDeceaseDate':
      return `Date de décès jusqu'au ${formatDate(value)}`

    case 'exposition':
      step = stepNames.find((s) => s.position === Number(arr[2]))
      if (!step) {
        return 'Erreur => étape introuvable'
      }
      return makeDurationLabel(Number(arr[0]), Number(arr[1]), step ? step.name : '')

    case 'variableResults':
      variable = variablesList.find((v) => v.id === Number(arr[0]))
      if (!variable) {
        return 'Erreur => variable introuvable'
      }
      return variable.aggregateCondition.type === 'stayDate'
        ? `${variable.name} du ${formatDate(Number(arr[1])*1000)} au ${formatDate(Number(arr[2])*1000)}`
        : `${variable.name} ${makeRangeLabel(arr[1], arr[2])}`

    default:
      return value
  }
}
function getModifiers(value: string): Modifiers {
  if (value.indexOf('\x1F') === -1) {
    return {}
  }

  const modifiers = {
    includeAntecedents: false,
    includeFamilyAntecedents: false,
    includeNegations: false,
    wordDistance: -1,
  }
  const strModif = value.substring(0, value.indexOf('\x1F'))
  const strModifSplit = strModif.split('_')

  _.forEach(strModifSplit, (modif) => {
    if (modif === 'a') {
      modifiers.includeAntecedents = true
    } else if (modif === 'f') {
      modifiers.includeFamilyAntecedents = true
    } else if (modif === 'n') {
      modifiers.includeNegations = true
    } else if (modif.substring(0, 2) === 'wd') {
      modifiers.wordDistance = Number(modif.substring(2))
    }
  })

  return modifiers
}

function makeRangeLabel(lowValue: string, highValue: string, suffix = ''): string {
  if (!lowValue || lowValue[0] === '<') {
    return `inférieur ou égal à ${highValue}${suffix}`
  }
  if (!highValue || highValue[0] === '>') {
    return `supérieur ou égal à ${lowValue}${suffix}`
  }

  return `de ${lowValue} à ${highValue}${suffix}`
}

const durationCommonDividers = [
  { label: 'ans', value: dayjs.duration(1, 'years').asDays() },
  { label: 'mois', value: dayjs.duration(1, 'months').asDays() },
  { label: 'semaines', value: dayjs.duration(1, 'weeks').asDays() },
  { label: 'jours', value: dayjs.duration(1, 'days').asDays() },
]
// takes two durations in ms, look for the greatest common divider for both durations
// return the label matching the correct date unit
function makeDurationLabel(value1: number, value2: number, stepName: string): string {
  let unit = 'ms'
  // UNSUPPORTED EDGE CASE: when one value is negative, the other shouldn't be greater than 0
  const when = value1 < 0 || value2 < 0 ? 'après' : 'avant'

  for (let i = 0; i < durationCommonDividers.length; ++i) {
    if (value1 % durationCommonDividers[i].value === 0 && value2 % durationCommonDividers[i].value === 0) {
      unit = durationCommonDividers[i].label
      value2 /= durationCommonDividers[i].value
      value1 /= durationCommonDividers[i].value
      break
    }
  }
  const [lesserDuration, greaterDuration] = [value1, value2].map((el) => Math.abs(el))
    .sort((a, b) => a-b)
  if (lesserDuration === 0) {
    return `Jusqu'à ${greaterDuration} ${unit} ${when} ${stepName}`
  }
  return `Entre ${lesserDuration} et ${greaterDuration} ${unit} ${when} ${stepName}`
}

export {
  getAggregationLabel,
  getCriteriaLabel,
  getModifiers,
  makeRangeLabel,
  makeDurationLabel,
  renderCriteria,
}
