import _ from 'lodash'

import filtersData from '@/filters/FiltersData.ts'
import { criterionListColumns, emptyStoredUserColumnsSettings } from '@/patient-list/PatientListColumns'
import { conditionKeyPrefix, emptyConditionGroup, emptySearch, keyPrefix } from '@/search/utils'
import { getters } from '@/store/modules/search/getters'
import { mutations } from '@/store/modules/search/mutations'
import { convertStudyNodeToSearch, uniqueId } from '@/study/utils.js'

const storedColumnsSettings = localStorage.getItem('userColumnsSettings')

export default {
  state: {
    currentSearch: _.cloneDeep(emptySearch),
    backupSearch: _.cloneDeep(emptySearch),
    patientListColumns: criterionListColumns,
    userColumnsSettings: storedColumnsSettings ? JSON.parse(storedColumnsSettings) : emptyStoredUserColumnsSettings(),
    isSearchEdited: false,
    filtersData: filtersData,
  },
  getters,
  mutations: {
    setCurrentSearch(state, search) {
      if (!search.globalConditionGroup) {
        search.globalConditionGroup = _.cloneDeep(emptyConditionGroup)
        search.globalConditionGroup.id = 'globalGroup'
      }
      state.currentSearch = _.cloneDeep(search)
      state.backupSearch = _.cloneDeep(search)
    },
    markSearchEdited(state) {
      state.isSearchEdited = true
    },
    unmarkSearchEdited(state) {
      state.isSearchEdited = false
    },
    resetNewSearch(state) {
      state.currentSearch = _.cloneDeep(emptySearch)
      state.backupSearch = _.cloneDeep(emptySearch)
      state.isSearchEdited = false
    },

    setSearchName(state, name) {
      state.currentSearch.name = name
      this.commit('markSearchEdited')
    },
    setSearchSnomed(state, snomedConcept) {
      state.currentSearch.snomedConceptId = snomedConcept.id
      state.currentSearch.snomedConceptTerm = snomedConcept.description
      this.commit('markSearchEdited')
    },
    updateOperators(state, operatorSystem) {
      state.currentSearch.searchOperators = operatorSystem
      this.commit('markSearchEdited')
    },
    resetSearchConditions(state) {
      state.currentSearch.globalConditionGroup = _.cloneDeep(state.backupSearch.globalConditionGroup)
      state.currentSearch.conditionGroups = _.cloneDeep(state.backupSearch.conditionGroups)
      state.currentSearch.searchOperators = state.backupSearch.searchOperators
      state.currentSearch.name = state.backupSearch.name
      this.commit('unmarkSearchEdited')
    },
    addConditionGroup(state) {
      const newConditionGroup = _.cloneDeep(emptyConditionGroup)
      newConditionGroup.id = uniqueId(keyPrefix)
      state.currentSearch.conditionGroups.push(newConditionGroup)
    },
    removeConditionGroup(state, { groupId }) {
      const groupIdx = state.currentSearch.conditionGroups.findIndex((group) => group.id === groupId)
      if (groupIdx === -1) {
        return
      }
      state.currentSearch.conditionGroups.splice(groupIdx, 1)
      this.commit('markSearchEdited')
    },
    addCondition(state, { groupId, type, value }) {
      const group = state.currentSearch.conditionGroups.find((group) => group.id === groupId)
      if (group === undefined) {
        return
      }
      // check duplicate
      if (group.conditionList.find((cond) => cond.type === type && cond.value === value)){
        return
      }
      group.conditionList.push({ type, value, id: uniqueId(conditionKeyPrefix), aggregation: null })
      this.commit('markSearchEdited')
    },
    removeCondition(state, { groupId, condition }) {
      const group = state.currentSearch.conditionGroups.find((group) => group.id === groupId)
      if (group === undefined) {
        return
      }
      const conditionIdx = group.conditionList.findIndex((c) => c.id === condition.id)
      if (conditionIdx === -1) {
        return
      }
      group.conditionList.splice(conditionIdx, 1)
      this.commit('markSearchEdited')
    },
    addGlobalCondition(state, { type, value }) {
      state.currentSearch.globalConditionGroup.conditionList.push({ type, value, id: uniqueId(conditionKeyPrefix) })
      this.commit('markSearchEdited')
    },
    removeGlobalCondition(state, { condition }) {
      const conditionIdx = state.currentSearch.globalConditionGroup.conditionList.findIndex((c) => c.id === condition.id)
      if (conditionIdx === -1) {
        return
      }
      state.currentSearch.globalConditionGroup.conditionList.splice(conditionIdx, 1)
      this.commit('markSearchEdited')
    },
    setCurrentSearchFromStudyNode(state, studyNode) {
      const search = convertStudyNodeToSearch(studyNode)
      this.commit('setCurrentSearch', search)
    },
    ...mutations,
  }
}
