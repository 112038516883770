<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      formError: null,
      forceLoader: false,
    }
  },
  computed: mapState({
    validityResetLink: (state) => state.login.validityResetLink,
    validityResetLinkRequest: (state) => state.login.validityResetLinkRequest,
    resetPasswordRequest: (state) => state.login.resetPasswordRequest,
  }),
  mounted() {
    this.$store.commit('resetPasswordRequestReset')
    this.$store.commit('validityResetLinkRequestReset')
    this.$store.dispatch('validityResetLink', { link: this.$route.params.link })
    this.forceLoader = true
    window.setTimeout(() => this.forceLoader = false, 1000)
  },
  methods: {
    handleSubmit() {
      if (this.password !== this.passwordConfirmation) {
        this.formError = 'Les mots de passe ne correspondent pas.'
        return
      }
      if (this.password.length < 8) {
        this.formError = 'Ce mot de passe est trop court'
        return
      }
      if (!this.password.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/)) {
        this.formError = 'Le mot de passe doit contenir au moins une minuscule, une majuscule et un chiffre.'
        return
      }
      this.formError = null
      this.$store.dispatch('resetPassword', { link: this.$route.params.link, password: this.password })
    },
  }
}
</script>

<template>
  <form @submit.prevent="handleSubmit">
    <h1 class="unlogged-page-title">
      Changement de mot de passe
    </h1>
    <div
      v-if="validityResetLinkRequest.fetching || forceLoader"
      class="text-center"
    >
      <div class="loader loader-lg loader-center mb-4" />
      <div>Vérification du lien...</div>
    </div>
    <div
      v-else-if="!validityResetLink"
      class="text-center"
    >
      <div class="text-danger">
        Ce lien de changement de mot de passe n'est pas valide.
      </div>
      <div>
        <router-link to="/login">
          &larr; Retour au login
        </router-link>
      </div>
    </div>
    <div v-else-if="validityResetLink && !resetPasswordRequest.ok">
      <div class="form-group">
        <label
          class="small-label"
          for="password"
        >
          Nouveau mot de passe
        </label>
        <input
          id="password"
          v-model="password"
          type="password"
          name="password"
          class="form-control login-input"
          autofocus
        >
      </div>
      <div class="form-group">
        <label
          class="small-label"
          for="confirmation"
        >
          Confirmation
        </label>
        <input
          id="confirmation"
          v-model="passwordConfirmation"
          type="password"
          name="confirmation"
          class="form-control login-input"
        >
      </div>
      <div class="text-right">
        <button
          class="btn btn-push btn-primary btn-padded"
          type="submit"
        >
          Valider
        </button>
      </div>
      <div class="unlogged-page-error-label">
        {{ formError }}
      </div>
    </div>
    <div
      v-if="resetPasswordRequest.ok"
      class="text-center text-success"
    >
      <div>Mot de passe mis à jour.</div>
      <div>
        <router-link to="/login">
          &larr; Retour au login
        </router-link>
      </div>
    </div>
  </form>
</template>
