<script>
import { InputAutoCompletion } from '@sancare/ui-frontend-commons'
import {
  useAutoCompletionDebouncedWatcher
} from '@sancare/ui-frontend-commons/src/misc/composables/useAutoCompletionDebouncedWatcher'
import { ref, watchEffect } from 'vue'
import { mapState } from 'vuex'

export default {
  components: {
    'input-auto-completion': InputAutoCompletion,
  },
  emits: ['select-criterion'],
  data() {
    return {
      searchInput: '',
      showCompletion: false,
    }
  },
  computed: {
    ...mapState({
      searchCriterionRequest: (state) => state.criteria.searchCriterionRequest,
      searchCriterionResults: (state) => state.criteria.searchCriterionResults,
    })
  },
  mounted() {
    const searchInputRef = ref(this.searchInput)
    const searchCriterionResultsRef = ref(this.searchCriterionResults)

    useAutoCompletionDebouncedWatcher(
      searchInputRef,
      searchCriterionResultsRef,
      (search, signal) => this.fetchCriterionData(search, signal),
      () => this.clearCriterionData(),
      2,
    )

    watchEffect(() => {
      searchInputRef.value = this.searchInput
    })
  },
  methods: {
    closeCompletion() {
      this.showCompletion = false
    },
    selectCriterion(criterion) {
      this.$emit('select-criterion', criterion)
      this.closeCompletion()
    },
    fetchCriterionData(search, signal) {
      this.$store.dispatch('searchCriterion', {
        search,
        signal,
      })
      this.searchInput = search
      this.showCompletion = true
    },
    clearCriterionData() {
      this.$store.commit('clearSearchCriterionResults')
      this.searchInput = ''
    },
  }
}
</script>

<template>
  <div
    v-on-click-outside="closeCompletion"
    @keydown.esc="closeCompletion"
  >
    <input
      v-model="searchInput"
      class="form-control"
      type="text"
      placeholder="Rechercher dans la bibliothèque de critères..."
    >
    <input-auto-completion
      v-show="showCompletion"
      :completion-list="searchCriterionResults"
      :is-loading="searchCriterionRequest.fetching"
      :title-prop="'name'"
      :description-prop="'name'"
      @select-item="selectCriterion"
    />
  </div>
</template>
