<script>
import dayjs from 'dayjs'
import _ from 'lodash'
import { mapState } from 'vuex'

import GenericThumbnailList from './GenericThumbnailList.vue'
import StudyList from './StudyList.vue'

export default {
  name: 'HomeList',
  components: {
    'generic-thumbnail-list': GenericThumbnailList,
    'study-list': StudyList,
  },
  data() {
    return {
      selectedSearches: [],
      criteriaThumbnailButtons: [
        {
          name: 'show-criteria',
          label: 'Gérer les critères',
          count: ' ',
          linkPath: '/criteria-manager',
          linkQuery: {}
        },
      ],
      variablesThumbnailButtons: [
        {
          name: 'show-variables',
          label: 'Gérer les variables d\'intérêt',
          count: ' ',
          linkPath: '/variable-manager',
          linkQuery: {}
        },
      ]
    }
  },
  computed: {
    ...mapState({
      noSearchWarning: (state) => state.settings.noSearchWarning,
      userSettings: (state) => state.settings.userSettings,
      currentUser: (state) => state.settings.currentUser,
      getStudiesRequest: (state) => state.study.request.getStudies,
      studies: (state) => _.orderBy(state.study.studies, [(study) => study.name.toLowerCase()]),
    }),
  },
  methods: {
    dismissSearchWarning() {
      this.$store.commit('setNoSearchWarning', dayjs().add(1, 'day'))
    },
    disableSearchWarning() {
      const settings = { ...this.userSettings }
      _.assign(settings, { dismissedMenus: ['searchWarning'] })
      this.$store.dispatch('setUserSettings', settings)
    },
    handleSelectStudy(study) {
      if (_.find(this.selectedSearches, (s) => s.id === study.id)) {
        this.selectedSearches = _.filter(this.selectedSearches, (s) => s.id !== study.id)
      } else {
        this.selectedSearches = _.concat(this.selectedSearches, study)
      }
    }
  },
}
</script>

<template>
  <div>
    <generic-thumbnail-list
      title="Bibliothèque des critères"
      :buttons="criteriaThumbnailButtons"
    />
    <generic-thumbnail-list
      title="Bibliothèque des variables d'intérêt"
      :buttons="variablesThumbnailButtons"
    />
    <study-list
      :study-list="studies"
      :title="'Études'"
      @select-study="handleSelectStudy"
    />
    <div v-if="getStudiesRequest.fetching">
      <div class="loader loader-lg loader-center" />
    </div>
  </div>
</template>
