<script>
import { SancareModal } from '@sancare/ui-frontend-commons'
import { mapState } from 'vuex'

export default {
  components: {
    'sancare-modal': SancareModal,
  },
  data() {
    return {
      openModal: true,
      password: '',
    }
  },
  computed: mapState({
    authRequestError: (state) => state.login.authRequest.error,
    username: (state) => state.login.username,
  }),
  methods: {
    submitLogin() {
      this.$store.dispatch('authenticate', { username: this.username, password: this.password })
    },
    cancel() {
      this.$store.dispatch('logout')
    },
  }
}
</script>

<template>
  <sancare-modal
    :open="openModal"
    :small="true"
  >
    <template #header>
      <div>
        Session expirée
      </div>
    </template>
    <form @submit.prevent="submitLogin">
      <p class="text-center">
        Voulez-vous continuer ?
      </p>
      <div class="form-group">
        <input
          v-model="password"
          name="password"
          type="password"
          class="form-control"
          placeholder="Password"
        >
      </div>
      <div class="form-group">
        <button
          type="submit"
          class="btn btn-push btn-primary btn-padded btn-fluid"
        >
          Valider
        </button>
      </div>
      <p
        v-if="authRequestError"
        class="text-danger text-center"
      >
        {{ authRequestError }}
      </p>
      <div class="text-center">
        <button
          type="button"
          class="btn btn-link"
          @click="cancel"
        >
          Retour au login
        </button>
      </div>
    </form>
  </sancare-modal>
</template>
