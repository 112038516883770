import { AbstractState } from '@sancare/ui-frontend-commons/src/store/types'

export type SnomedConcept = {
  id: string
  description: string
}

export class SnomedState extends AbstractState {
  completionConcepts: SnomedConcept[] = []
}
