import Api from '@sancare/ui-frontend-commons/src/misc/api'

import ApiLegacy from '../misc/api'

// If we are logged in but need to resume a session,
// we delay all subsequent API calls
if (!window.sessionIsValid && localStorage.getItem('username')) {
  ApiLegacy.startDelayingCalls()
  Api.startDelayingCalls()
}

export default {
  state: {
    username: localStorage.getItem('username'),
    userRole: localStorage.getItem('userRole'),
    // If the session was unvalidated and never restarted last time, we are brought back to the login
    isAuthenticated: localStorage.getItem('username') && localStorage.getItem('sessionCanBeResumed'),
    sessionIsValid: window.sessionIsValid,
    authRequest: { fetching: false, error: null, ok: false },
    expiredData: { username: null },
    expiredPassword: false,
    expiredPasswordRequest: { fetching: false, error: null, ok: false },
    forgottenPasswordRequest: { fetching: false, error: null, ok: false },
    validityResetLink: null,
    validityResetLinkRequest: { fetching: false, error: null, ok: false },
    resetPasswordRequest: { fetching: false, error: null, ok: false },
    logoutRequest: { fetching: false, error: null, ok: false },
  },
  getters: {
    userRole: (state) => state.userRole,
    isAuthenticated: (state) => state.isAuthenticated,
    sessionIsValid: (state) => state.sessionIsValid,
    username: (state) => state.username,
  },
  mutations: {
    authRequestStart(state) {
      state.authRequest = { fetching: true, error: null, ok: false }
      state.expiredPassword = false
    },
    authRequestSuccess(state, response) {
      state.authRequest.fetching = false
      state.authRequest.error = null
      state.authRequest.ok = true

      state.username = response.username
      localStorage.setItem('username', response.username)
      state.userRole = response.role
      localStorage.setItem('userRole', response.role)

      state.isAuthenticated = true
      state.sessionIsValid = true
      localStorage.setItem('sessionCanBeResumed', 1),
      ApiLegacy.resumeDelayedCalls()
      Api.resumeDelayedCalls()
    },
    authRequestError(state, res) {
      state.authRequest.fetching = false
      state.authRequest.error = res.message
      state.expiredPassword = Boolean(res.rawError.expiredPassword && res.rawError.expiredPassword === 1)
    },
    authRequestReset(state) {
      state.authRequest = { fetching: false, error: null, ok: false }
    },
    expiredData(state, expiredData) {
      state.expiredData = expiredData
    },
    expiredPasswordRequestReset(state) {
      state.expiredPasswordRequest = { fetching: false, error: null, ok: false }
      state.expiredPassword = false
    },
    expiredPasswordRequestStart(state) {
      state.expiredPasswordRequest = { fetching: true, error: null, ok: false }
    },
    expiredPasswordRequestSuccess(state) {
      state.expiredPasswordRequest = { fetching: false, error: null, ok: true }
      state.expiredPassword = false
    },
    expiredPasswordRequestError(state, res) {
      state.expiredPasswordRequest = { fetching: false, ok: false, error: res.message }
    },
    forgottenPasswordRequestStart(state) {
      state.forgottenPasswordRequest = { fetching: true, error: null, ok: false }
    },
    forgottenPasswordRequestSuccess(state) {
      state.forgottenPasswordRequest.fetching = false
      state.forgottenPasswordRequest.ok = true
    },
    forgottenPasswordRequestError(state, res) {
      // FIXME
      // the server should retrun error keys such as "NO_SUCH_USER" or "UNHANDLED_USER"
      // which are translated into messages in the frontend
      if (res.status === 404) {
        state.forgottenPasswordRequest = { fetching: false, ok: false, error: 'Cet identifiant n\'est pas reconnu.' }
      } else if (res.status === 422) {
        state.forgottenPasswordRequest = { fetching: false, ok: false, error: 'Cet utilisateur est géré par l\'authentification centralisée.' }
      } else {
        state.forgottenPasswordRequest = { fetching: false, ok: false, error: res.message }
      }
    },
    forgottenPasswordRequestReset(state) {
      state.forgottenPasswordRequest = { fetching: false, error: null, ok: false }
    },
    validityResetLinkRequestStart(state) {
      state.validityResetLinkRequest = { fetching: true, error: null, ok: false }
    },
    validityResetLinkRequestSuccess(state, validity) {
      state.validityResetLinkRequest.fetching = false
      state.validityResetLinkRequest.ok = true
      state.validityResetLink = validity
    },
    validityResetLinkRequestError(state, res) {
      state.validityResetLinkRequest.fetching = false
      state.validityResetLinkRequest.error = res.message
    },
    validityResetLinkRequestReset(state) {
      state.validityResetLinkRequest = { fetching: false, error: null, ok: false }
      state.validityResetLink = null
    },
    resetPasswordRequestStart(state) {
      state.resetPasswordRequest = { fetching: true, error: null, ok: false }
    },
    resetPasswordRequestSuccess(state) {
      state.resetPasswordRequest.fetching = false
      state.resetPasswordRequest.ok = true
    },
    resetPasswordRequestError(state, res) {
      state.resetPasswordRequest.fetching = false
      state.resetPasswordRequest.error = res.message
    },
    resetPasswordRequestReset(state) {
      state.resetPasswordRequest = { fetching: false, error: null, ok: false }
      state.expiredPassword = false
    },
    logoutRequestStart(state) {
      state.logoutRequest = { fetching: true, error: null, ok: false }
    },
    logoutRequestSuccess(state) {
      state.logoutRequest.fetching = false
      state.logoutRequest.ok = true

      state.username = null
      state.userRole = null
      state.isAuthenticated = false
      state.sessionIsValid = false
      localStorage.clear()
      sessionStorage.clear()
      ApiLegacy.rejectDelayedCalls()
      Api.rejectDelayedCalls()
    },
    logoutRequestError(state, res) {
      state.logoutRequest.fetching = false
      state.logoutRequest.error = res.message
    },
    sessionExpired(state) {
      state.sessionIsValid = false
      localStorage.removeItem('sessionCanBeResumed')
    },
  },
  actions: {
    authenticate({ commit }, { username, password }) {
      return ApiLegacy.post(
        {
          url: '/api/authenticate',
          body: { username, password },
          startMut: 'authRequestStart',
          successMut: 'authRequestSuccess',
          errorMut: 'authRequestError',
          doNotRetry: true,
          cannotBeDelayed: true,
        },
        commit
      )
    },
    forgottenPassword({ commit }, { username }) {
      return ApiLegacy.post(
        {
          url: '/api/request-reset-password',
          body: { username },
          startMut: 'forgottenPasswordRequestStart',
          successMut: 'forgottenPasswordRequestSuccess',
          errorMut: 'forgottenPasswordRequestError',
          doNotRetry: true,
          cannotBeDelayed: true,
        },
        commit
      )
    },
    validityResetLink({ commit }, { link }) {
      return ApiLegacy.get(
        {
          url: `/api/validity-reset-link/${link}`,
          startMut: 'validityResetLinkRequestStart',
          successMut: 'validityResetLinkRequestSuccess',
          errorMut: 'validityResetLinkRequestError',
          doNotRetry: true,
          cannotBeDelayed: true,
        },
        commit
      )
    },
    resetPassword({ commit }, { link, password }) {
      return ApiLegacy.post(
        {
          url: `/api/reset-password/${link}`,
          body: { password },
          startMut: 'resetPasswordRequestStart',
          successMut: 'resetPasswordRequestSuccess',
          errorMut: 'resetPasswordRequestError',
          doNotRetry: true,
          cannotBeDelayed: true,
        },
        commit
      )
    },
    expiredPassword({ commit }, { username, password, newPassword, confirmationPassword }) {
      return ApiLegacy.post(
        {
          url: '/api/expired-password',
          body: { username, password, newPassword, confirmationPassword },
          startMut: 'expiredPasswordRequestStart',
          successMut: 'expiredPasswordRequestSuccess',
          errorMut: 'expiredPasswordRequestError',
          doNotRetry: true,
          cannotBeDelayed: true,
        },
        commit
      )
    },
    logout({ commit }) {
      return ApiLegacy.get(
        {
          url: '/api/logout',
          startMut: 'logoutRequestStart',
          successMut: 'logoutRequestSuccess',
          errorMut: 'logoutRequestError',
          doNotRetry: true,
          cannotBeDelayed: true,
        },
        commit
      )
    },
  },
}
