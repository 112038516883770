import { ApiRequest } from '@sancare/ui-frontend-commons/src/misc/api-request'

import { SnomedState } from './types'

const baseUrl = '/api/snomed'

const state = new SnomedState()
state.requests = {
  conceptCompletion: new ApiRequest('conceptCompletion', 'GET', `${baseUrl}/concepts/completion`)
}

export { state }
