import { SettingDataGroups } from '@/store/modules/settings/types'

export default {
  interface: {
    label: 'Paramètres d\'interface',
    settings: [
      {
        name: 'enable_compute_study_jobs',
        label: 'Calcul des études en tâche de fond',
        type: 'bool',
      },
    ],
  },
  import: {
    label: 'Paramètres d\'import des séjours',
    settings: [
      {
        name: 'restricted_legal_finess',
        label: 'Restriction par FINESS juridique',
        type: 'finess',
      },
      {
        name: 'restricted_geo_finess',
        label: 'Restriction par FINESS géographique',
        type: 'finess',
      }
    ]
  }
} as SettingDataGroups
