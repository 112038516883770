import dayjs from 'dayjs'

import { Stay } from '@/store/modules/stay/types'
import { StayMatch } from '@/text-processing/types'

export function getStayLabel(stay: Stay|StayMatch): string {
  const stayStart = stay.stayStart ? dayjs(stay.stayStart).format('MM/YYYY') : '???'
  const stayEnd = stay.stayEnd ? dayjs(stay.stayEnd).format('MM/YYYY') : '???'
  const truncDates = stayStart === stayEnd ? stayEnd : `${stayStart} - ${stayEnd}`

  return `${truncDates} (${stay.stayDuration ?? '?'}j)`
}
