/**
 * keep in sync with VariableVoter.php
 */
import { hasPermission, RoleEnum } from '@/misc/permissions/permissionsHelper'
import { User } from '@/settings/types'
import { Variable } from '@/variables/types'

function isOwner(variable: Variable, user: User): boolean {
  return variable.user.id === user.id
}

function canCreate(): boolean {
  return true
}
function canEdit(variable: Variable, user: User): boolean {
  return hasPermission(RoleEnum.ROLE_STUDY_ADMIN, user.role)
        || isOwner(variable, user)
}
function canShow(): boolean {
  return true
}

export default {
  canCopy: (): boolean => {
    return canCreate() && canShow()
  },
  canCreate,
  canDelete: (variable: Variable, user: User): boolean => {
    return canEdit(variable, user)
  },
  canEdit,
  canShow,
}
