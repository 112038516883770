import Api from '@sancare/ui-frontend-commons/src/misc/api'
import { ApiRequest } from '@sancare/ui-frontend-commons/src/misc/api-request'
import _ from 'lodash'

import LegacyApi from '../misc/api'

export default {
  state: {
    userList: [],
    legalFinessList: [],
    geoFinessList: [],
    userListRequest: { fetching: false, error: null, ok: false },
    createUserRequest: { fetching: false, error: null, ok: false },
    updateUserRequest: { fetching: false, error: null, ok: false },
    deleteUserRequest: { fetching: false, error: null, ok: false },
    enableUserRequest: { fetching: false, error: null, ok: false },
    assignLegalFinessRequest: { fetching: false, error: null, ok: false },
    unassignLegalFinessRequest: { fetching: false, error: null, ok: false },
    assignGeoFinessRequest: { fetching: false, error: null, ok: false },
    unassignGeoFinessRequest: { fetching: false, error: null, ok: false },
    searchLegalFinessRequest: { fetching: false, error: null, ok: false },
    searchGeoFinessRequest: { fetching: false, error: null, ok: false },
  },
  getters: {
    getUserById: (state) => (id) => {
      return _.find(state.userList, (u) => u.id === id)
    },
  },
  mutations: {
    userListRequestStart(state) {
      state.userListRequest = { fetching: true, error: null, ok: false }
    },
    userListRequestSuccess(state, userList) {
      state.userListRequest = { fetching: false, error: null, ok: true }
      state.userList = userList
    },
    userListRequestError(state, res) {
      state.userListRequest = { fetching: false, error: res.message, ok: false }
    },
    createUserRequestStart(state) {
      state.createUserRequest = { fetching: true, error: null, ok: false }
    },
    createUserRequestSuccess(state, newUser) {
      state.createUserRequest = { fetching: false, error: null, ok: true }
      state.userList.push(newUser)
    },
    createUserRequestError(state, res) {
      state.createUserRequest = { fetching: false, error: res.message, ok: false }
    },
    resetCreateUserRequest(state) {
      state.createUserRequest = { fetching: false, error: null, ok: false }
    },
    updateUserRequestStart(state) {
      state.updateUserRequest = { fetching: true, error: null, ok: false }
    },
    updateUserRequestSuccess(state, newUser) {
      state.updateUserRequest = { fetching: false, error: null, ok: true }
      state.userList.splice(_.findIndex(state.userList, (u) => u.id === newUser.id), 1, newUser)
    },
    updateUserRequestError(state, res) {
      state.updateUserRequest = { fetching: false, error: res.message, ok: false }
    },
    resetUpdateUserRequest(state) {
      state.updateUserRequest = { fetching: false, error: null, ok: false }
    },
    deleteUserRequestStart(state) {
      state.deleteUserRequest = { fetching: true, error: null, ok: false }
    },
    deleteUserRequestSuccess(state) {
      state.deleteUserRequest = { fetching: false, error: null, ok: true }
    },
    deleteUserRequestError(state, res) {
      state.deleteUserRequest = { fetching: false, error: res.message, ok: false }
    },
    enableUserRequestStart(state) {
      state.enableUserRequest = { fetching: true, error: null, ok: false }
    },
    enableUserRequestSuccess(state) {
      state.enableUserRequest = { fetching: false, error: null, ok: true }
    },
    enableUserRequestError(state, res) {
      state.enableUserRequest = { fetching: false, error: res.message, ok: false }
    },
    resetdeleteUserRequest(state) {
      state.deleteUserRequest = { fetching: false, error: null, ok: false }
    },
    assignLegalFinessRequestStart(state) {
      state.assignLegalFinessRequest = { fetching: true, error: null, ok: false }
    },
    assignLegalFinessRequestSuccess(state, editedUser) {
      state.userList.splice(_.findIndex(state.userList, (u) => u.id === editedUser.id), 1, editedUser)
      state.assignLegalFinessRequest = { fetching: false, error: null, ok: true }
    },
    assignLegalFinessRequestError(state, res) {
      state.assignLegalFinessRequest = { fetching: false, error: res.message, ok: false }
    },
    unassignLegalFinessRequestStart(state) {
      state.unassignLegalFinessRequest = { fetching: true, error: null, ok: false }
    },
    unassignLegalFinessRequestSuccess(state, editedUser) {
      state.userList.splice(_.findIndex(state.userList, (u) => u.id === editedUser.id), 1, editedUser)
      state.unassignLegalFinessRequest = { fetching: false, error: null, ok: true }
    },
    unassignLegalFinessRequestError(state, res) {
      state.unassignLegalFinessRequest = { fetching: false, error: res.message, ok: false }
    },
    assignGeoFinessRequestStart(state) {
      state.assignGeoFinessRequest = { fetching: true, error: null, ok: false }
    },
    assignGeoFinessRequestSuccess(state, editedUser) {
      state.userList.splice(_.findIndex(state.userList, (u) => u.id === editedUser.id), 1, editedUser)
      state.assignGeoFinessRequest = { fetching: false, error: null, ok: true }
    },
    assignGeoFinessRequestError(state, res) {
      state.assignGeoFinessRequest = { fetching: false, error: res.message, ok: false }
    },
    unassignGeoFinessRequestStart(state) {
      state.unassignGeoFinessRequest = { fetching: true, error: null, ok: false }
    },
    unassignGeoFinessRequestSuccess(state, editedUser) {
      state.userList.splice(_.findIndex(state.userList, (u) => u.id === editedUser.id), 1, editedUser)
      state.unassignGeoFinessRequest = { fetching: false, error: null, ok: true }
    },
    unassignGeoFinessRequestError(state, res) {
      state.unassignGeoFinessRequest = { fetching: false, error: res.message, ok: false }
    },
    searchLegalFinessRequestStart(state) {
      state.searchLegalFinessRequest = { fetching: true, error: null, ok: false }
    },
    searchLegalFinessRequestError(state, res) {
      state.searchLegalFinessRequest = { fetching: false, error: res.message, ok: false }
      state.legalFinessList = []
    },
    searchLegalFinessRequestSuccess(state, finessList) {
      state.searchLegalFinessRequest = { fetching: false, error: null, ok: true }
      // map the items in a standard array for the InputAutoCompletion vue
      state.legalFinessList = _.map(finessList, (item) => {
        return {
          reference: item.finess,
          label: item.shortDescription,
        }
      })
    },
    searchGeoFinessRequestStart(state) {
      state.searchGeoFinessRequest = { fetching: true, error: null, ok: false }
    },
    searchGeoFinessRequestError(state, res) {
      state.searchGeoFinessRequest = { fetching: false, error: res.message, ok: false }
      state.geoFinessList = []
    },
    searchGeoFinessRequestSuccess(state, finessList) {
      state.searchGeoFinessRequest = { fetching: false, error: null, ok: true }
      // map the items in a standard array for the InputAutoCompletion vue
      state.geoFinessList = _.map(finessList, (item) => {
        return {
          reference: item.finess,
          label: item.shortDescription,
        }
      })
    },
    searchLegalFinessRequestClear(state) {
      state.legalFinessList = []
    },
    searchGeoFinessRequestClear(state) {
      state.geoFinessList = []
    },
  },
  actions: {
    getUserList({ commit }) {
      return LegacyApi.get(
        {
          url: '/api/user/',
          startMut: 'userListRequestStart',
          successMut: 'userListRequestSuccess',
          errorMut: 'authRequestError',
        },
        commit
      )
    },
    createUser({ commit }, payload) {
      return LegacyApi.post(
        {
          url: '/api/user/',
          body: {
            firstName: payload.firstName,
            lastName: payload.lastName,
            role: payload.role,
            email: payload.email,
            username: payload.username,
            password: payload.password,
            teamId: payload.teamId,
            // teamName: payload.teamId === -1 ? payload.teamName : undefined
          },
          startMut: 'createUserRequestStart',
          successMut: 'createUserRequestSuccess',
          errorMut: 'createUserRequestError',
        },
        commit
      )
    },
    updateUser({ commit }, payload) {
      return LegacyApi.put(
        {
          url: `/api/user/${payload.id}`,
          body: {
            firstName: payload.firstName,
            lastName: payload.lastName,
            role: payload.role,
            teamId: payload.teamId,
            // teamName: payload.teamId === -1 && payload.teamName ? payload.teamName : undefined,
            password: payload.password ? payload.password : undefined
          },
          startMut: 'updateUserRequestStart',
          successMut: 'updateUserRequestSuccess',
          errorMut: 'updateUserRequestError',
        },
        commit
      )
    },
    deleteUser({ commit }, userId) {
      return LegacyApi.del(
        {
          url: `/api/user/${userId}`,
          body: '',
          startMut: 'deleteUserRequestStart',
          successMut: 'deleteUserRequestSuccess',
          errorMut: 'deleteUserRequestError',
        },
        commit
      )
    },
    enableUser({ commit }, userId) {
      return LegacyApi.post(
        {
          url: `/api/user/${userId}`,
          body: '',
          startMut: 'enableUserRequestStart',
          successMut: 'enableUserRequestSuccess',
          errorMut: 'enableUserRequestError',
        },
        commit
      )
    },
    assignLegalFiness({ commit }, { userId, finess }) {
      return LegacyApi.post(
        {
          url: `/api/user/${userId}/add-legal-finess`,
          body: { finess },
          startMut: 'assignLegalFinessRequestStart',
          successMut: 'assignLegalFinessRequestSuccess',
          errorMut: 'assignLegalFinessRequestError',
        },
        commit
      )
    },
    unassignLegalFiness({ commit }, { userId, finess }) {
      return LegacyApi.post(
        {
          url: `/api/user/${userId}/rm-legal-finess`,
          body: { finess },
          startMut: 'unassignLegalFinessRequestStart',
          successMut: 'unassignLegalFinessRequestSuccess',
          errorMut: 'unassignLegalFinessRequestError',
        },
        commit
      )
    },
    assignGeoFiness({ commit }, { userId, finess }) {
      return LegacyApi.post(
        {
          url: `/api/user/${userId}/add-geo-finess`,
          body: { finess },
          startMut: 'assignGeoFinessRequestStart',
          successMut: 'assignGeoFinessRequestSuccess',
          errorMut: 'assignGeoFinessRequestError',
        },
        commit
      )
    },
    unassignGeoFiness({ commit }, { userId, finess }) {
      return LegacyApi.post(
        {
          url: `/api/user/${userId}/rm-geo-finess`,
          body: { finess },
          startMut: 'unassignGeoFinessRequestStart',
          successMut: 'unassignGeoFinessRequestSuccess',
          errorMut: 'unassignGeoFinessRequestError',
        },
        commit
      )
    },
    searchLegalFinessRequest({ commit }, { search, signal }) {
      return Api.request(
        new ApiRequest('searchLegalFiness', 'POST', '/api/user/search-legal-finess'),
        {
          body: { search },
          signal,
          startMut: 'searchLegalFinessRequestStart',
          successMut: 'searchLegalFinessRequestSuccess',
          errorMut: 'searchLegalFinessRequestError',
        },
        commit,
      )
    },
    searchGeoFinessRequest({ commit }, { search, signal }) {
      return Api.request(
        new ApiRequest('searchGeoFiness', 'POST', '/api/user/search-geo-finess'),
        {
          body: { search },
          signal,
          startMut: 'searchGeoFinessRequestStart',
          successMut: 'searchGeoFinessRequestSuccess',
          errorMut: 'searchGeoFinessRequestError',
        },
        commit,
      )
    },
  }
}
