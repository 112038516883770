import _ from 'lodash'

import { patientIdColumn, patientIdColumnName } from '@/patient-list/PatientListColumns'
import { PatientListColumn, PatientListColumnEnum } from '@/store/modules/patient/types'
import { Condition, SearchState } from '@/store/modules/search/types'
import { AggregationModifiers } from '@/variables/types'

export const mutations = {
  setAggregateCondition(state: SearchState, { condition, aggregationModifiers }: { condition: Condition, aggregationModifiers: AggregationModifiers }) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore todo use currentCriterion and currentVariable properties in state
    state.currentSearch.aggregateCondition = condition
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.currentSearch.modifiers = aggregationModifiers
    this.commit('markSearchEdited')
  },
  removeAggregateCondition(state: SearchState) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore todo use currentCriterion and currentVariable properties in state
    state.currentSearch.aggregateCondition = null
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.currentSearch.modifiers = null
    this.commit('markSearchEdited')
  },
  setPatientListColumns(state: SearchState, columns: { [key: string]: PatientListColumn }) {
    state.patientListColumns = columns
  },
  setDisplayedColumns(state: SearchState, { columns, studyId }: { columns: { [key: string]: PatientListColumn }, studyId: number }) {
    if (!studyId) {
      state.userColumnsSettings.defaultCriterion = { [patientIdColumnName]: patientIdColumn, ...columns }
    } else {
      state.userColumnsSettings.defaultStudy = _.pickBy(columns, (column) => column.type === PatientListColumnEnum.SYSTEM)
      // remove invalid variables
      const validVariableIds = Object.values(_.pickBy(state.patientListColumns, (column) => column.type !== PatientListColumnEnum.SYSTEM)).map((column: PatientListColumn) => column.id)
      state.userColumnsSettings.studies[studyId] = {}
      state.userColumnsSettings.studies[studyId][patientIdColumnName] = patientIdColumn
      Object.assign(state.userColumnsSettings.studies[studyId], _.pickBy(columns, (column) => column.type === PatientListColumnEnum.SYSTEM || validVariableIds.includes(column.id)))
    }
    localStorage.setItem('userColumnsSettings', JSON.stringify(state.userColumnsSettings))
  },
  setVariableDisplayedColumns(state: SearchState, { columns, isAggregated }: { columns: { [key: string]: PatientListColumn }, isAggregated: boolean }) {
    if (isAggregated) {
      state.userColumnsSettings.aggregatedVariable = columns
    } else {
      state.userColumnsSettings.variable = columns
    }
    localStorage.setItem('userColumnsSettings', JSON.stringify(state.userColumnsSettings))
  },
}
