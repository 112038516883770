import { SearchState } from '@/store/modules/search/types'

export const getters = {
  getUserColumnsSettings: (state: SearchState) => (studyId: number) => {
    if (!studyId) {
      return state.userColumnsSettings.defaultCriterion
    }
    return state.userColumnsSettings.studies[studyId] ?? state.userColumnsSettings.defaultStudy
  },
  getUserVariableColumnsSettings: (state: SearchState) => (isAggregated: boolean) => {
    return isAggregated ? state.userColumnsSettings.aggregatedVariable : state.userColumnsSettings.variable
  },
}
