<script>
import { capitalize } from '@sancare/ui-frontend-commons/src/misc/string-utils'

export default {
  props: {
    filterData: { type: Object, required: true },
  },
  emits: ['add-filter'],
  data() {
    const subFiltersValues = {}

    if (this.filterData.subFilters) {
      this.filterData.subFilters.forEach((filter) => {
        if (filter.default) {
          subFiltersValues[filter.name] = filter.default

          return
        }

        subFiltersValues[filter.name] = filter.options ? filter.options[0] : ''
      })
    }

    return {
      subFiltersValues: subFiltersValues,
      value: this.filterData.default ? this.filterData.default : '',
    }
  },
  methods: {
    addFilter() {
      if (!this.value || this.value === '') {
        return
      }

      const name = this.filterData.name
      let type = this.filterData.format
      const value = this.value

      type = type.replace('{filterType}', capitalize(this.filterData.name))
      if (this.filterData.subFilters) {
        this.filterData.subFilters.forEach((filter) => {
          type = type.replace(`{${filter.name}}`, capitalize(this.subFiltersValues[filter.name]))
        })
      }
      type = type.charAt(0).toLowerCase() + type.slice(1)
      this.$emit('add-filter', { name, type, value })
    },
  },
}
</script>

<template>
  <div class="row">
    <div
      class="input-group col-12"
    >
      <select
        v-for="filter in filterData.subFilters"
        :key="filter.name"
        v-model="subFiltersValues[filter.name]"
        class="custom-select"
      >
        <option
          value=""
          disabled
        >
          ---
        </option>
        <option
          v-for="opt in filter.options"
          :key="opt"
          :value="opt"
        >
          {{ filter.labels[opt] ? filter.labels[opt] : opt }}
        </option>
      </select>
    </div>
    <div
      class="input-group col"
    >
      <select
        v-model="value"
        class="custom-select"
        @change="addFilter"
      >
        <option
          value=""
          disabled
        >
          ---
        </option>
        <option
          v-for="opt in filterData.options"
          :key="opt"
          :value="opt"
        >
          {{ (filterData.labels && filterData.labels[opt]) ? filterData.labels[opt] : opt }}
        </option>
      </select>
      <div class="input-group-append">
        <button
          class="btn btn-primary"
          type="button"
          @click="addFilter"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
</template>
