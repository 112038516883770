import _ from 'lodash'

/**
 * @deprecated use @sancare/ui-frontend-commens/misc/api.ts instead
 */
class Api {
  static async _request(method, { url, body, startMut, successMut, errorMut, doNotRetry = false, cannotBeDelayed = false }, commit, retryIfUnlogged = true) {
    try {
      if (_.isString(startMut)) {
        commit(startMut)
      } else if (_.isFunction(startMut)) {
        startMut()
      }

      const options = {
        method,
        credentials: 'same-origin',
      }

      // Disable all AJAX caching, and make explicit the usage of XMLHttpRequest
      options.headers = new Headers({
        'Cache-Control': 'no-cache, no-store, must-revalidate, max-age=0',
        expires: '0',
        pragma: 'no-cache',
        'X-Requested-With': 'XMLHttpRequest',
      })

      if (body) {
        options.body = JSON.stringify(body)
      }
      if (!cannotBeDelayed && !this.delayCalls.finished) {
        await this.delayCalls.promise
      }
      const res = await fetch(url, options)
      const responseBody = await res.text()

      let responseJson = null
      if (res.status !== 204) {
        try {
          responseJson = JSON.parse(responseBody)
        } catch (err) {
          // eslint-disable-next-line no-console
          console.warn('Response body was not JSON')
          responseJson = null
        }
      }

      if (res.ok) {
        if (_.isString(successMut)) {
          return commit(successMut, responseJson)
        }
        if (_.isFunction(successMut)) {
          return successMut(responseJson)
        }
      }
      if (res.status === 401) {
        commit('sessionExpired')

        // this parameter is used to avoid inifite recursion
        if (retryIfUnlogged && !doNotRetry) {
          this.startDelayingCalls()
          try {
            await this.delayCalls.promise
            return this._request(method, { url, body, successMut, errorMut }, commit, false)
          } catch (e) {
            // There is no use handling the request that 401-ed, since the user just cancelled.
            return null
          }
        }
      }

      const errObj = { message: 'Une erreur est survenue', status: res.status, rawError: responseJson }
      if (responseJson && responseJson.message) {
        errObj.message = responseJson.message
      }
      commit('toast/setError', errObj.message)
      if (_.isString(errorMut)) {
        return commit(errorMut, errObj)
      }
      if (_.isFunction(errorMut)) {
        return errorMut(errObj)
      }

      return null
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      const message = 'Une erreur est survenue.'
      commit('toast/setError', message)
      if (_.isString(errorMut)) {
        return commit(errorMut, { message })
      }
      if (_.isFunction(errorMut)) {
        return errorMut({ message })
      }

      return null
    }
  }
  static async get(params, commit) {
    await this._request('GET', params, commit)
  }
  static async post(params, commit) {
    await this._request('POST', params, commit)
  }
  static async del(params, commit) {
    await this._request('DELETE', params, commit)
  }
  static async put(params, commit) {
    await this._request('PUT', params, commit)
  }
  static startDelayingCalls() {
    if (this.delayCalls.finished) {
      this.delayCalls.finished = false
      this.delayCalls.promise = new Promise((resolve, reject) => {
        this.delayCalls.resolve = resolve
        this.delayCalls.reject = reject
      })
    }
  }
  static resumeDelayedCalls() {
    if (!this.delayCalls.finished) {
      this.delayCalls.resolve()
      this.delayCalls.finished = true
    }
  }
  static rejectDelayedCalls() {
    if (!this.delayCalls.finished) {
      this.delayCalls.reject('Api call was cancelled')
      this.delayCalls.finished = true
    }
  }
}

// Static property, that will contain a Promise
// if we have to wait before re-firing the API calls.
Api.delayCalls = {
  promise: null,
  finished: true,
  resolve: () => null,
  reject: () => null,
}

export default Api
