import { setRequest } from '@sancare/ui-frontend-commons/src/misc/api'

import { formatJob } from '@/store/modules/job/formatter'
import { JobState, RawJob } from '@/store/modules/job/types'

export const mutations = {
  setRequest,
  setJobs(state: JobState, jobs: RawJob[]) {
    state.jobs = jobs.map((job) => formatJob(job))
  },
}
