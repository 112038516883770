import { setRequest } from '@sancare/ui-frontend-commons/src/misc/api'

import { SnomedConcept, SnomedState } from '@/store/modules/snomed/types'

export const mutations = {
  setRequest,
  conceptCompletionSuccess: (state: SnomedState, response: SnomedConcept[]) => {
    state.completionConcepts = response
  },
  clearCompletionItems: (state: SnomedState) => {
    state.completionConcepts = []
  }
}
