import Api from '@sancare/ui-frontend-commons/src/misc/api'
import { ApiRequest } from '@sancare/ui-frontend-commons/src/misc/api-request'
import _ from 'lodash'

import LegacyApi from '../misc/api'

export default {
  state: {
    variables: [],
    searchVariableResults: [],

    addVariableRequest: { fetching: false, error: null, ok: false },
    editVariableRequest: { fetching: false, error: null, ok: false },
    copyVariableRequest: { fetching: false, error: null, ok: false },
    fetchVariableRequest: { fetching: false, error: null, ok: false },
    fetchVariablesRequest: { fetching: false, error: null, ok: false },
    removeVariableRequest: { fetching: false, error: null, ok: false },
    searchVariableRequest: { fetching: false, error: null, ok: false },
    addVariableToStudyRequest: { fetching: false, error: null, ok: false },
    removeVariableFromStudyRequest: { fetching: false, error: null, ok: false },
  },
  mutations: {
    copyVariableStart(state) {
      state.copyVariableRequest = { fetching: true, error: null, ok: false }
    },
    copyVariableSuccess(state, response) {
      state.copyVariableRequest = { fetching: false, error: null, ok: true }
      state.variables.push(response)
    },
    copyVariableError(state, res) {
      state.copyVariableRequest = { fetching: false, error: res.message, ok: false }
    },
    fetchVariableStart(state) {
      state.fetchVariableRequest = { fetching: true, error: null, ok: false }
    },
    fetchVariableSuccess(state) {
      state.fetchVariableRequest = { fetching: false, error: null, ok: true }
    },
    fetchVariableError(state, res) {
      state.fetchVariableRequest = { fetching: false, error: res.message, ok: false }
    },
    fetchVariablesStart(state) {
      state.fetchVariablesRequest = { fetching: true, error: null, ok: false }
    },
    fetchVariablesSuccess(state, variables) {
      state.fetchVariablesRequest = { fetching: false, error: null, ok: true }
      state.variables = variables
    },
    fetchVariablesError(state, res) {
      state.fetchVariablesRequest = { fetching: false, error: res.message, ok: false }
      state.variables = []
    },
    removeVariableStart(state) {
      state.removeVariableRequest = { fetching: true, error: null, ok: false }
    },
    removeVariableSuccess(state, variableId) {
      state.removeVariableRequest = { fetching: false, error: false, ok: true }
      state.variables = _.filter(state.variables, (s) => s.id !== variableId)
    },
    removeVariableError(state, res) {
      state.removeVariableRequest = { fetching: false, error: res.message, ok: false }
    },
    searchVariableStart(state) {
      state.searchVariableRequest = { fetching: true, error: null, ok: false }
    },
    searchVariableSuccess(state, response) {
      state.searchVariableRequest = { fetching: false, error: false, ok: true }
      state.searchVariableResults = response
    },
    searchVariableError(state, res) {
      state.searchVariableRequest = { fetching: false, error: res.message, ok: false }
    },
    clearSearchVariableResults(state) {
      state.searchVariableResults = []
    },

    addVariableStart(state) {
      state.addVariableRequest = { fetching: true, error: null, ok: false }
    },
    addVariableSuccess(state, variable) {
      state.addVariableRequest = { fetching: false, error: null, ok: true }
      state.variables.push(variable)
    },
    addVariableError(state, res) {
      state.addVariableRequest = { fetching: false, error: res.message, ok: false }
    },
    editVariableStart(state) {
      state.editVariableRequest = { fetching: true, error: null, ok: false }
    },
    editVariableSuccess(state) {
      state.editVariableRequest = { fetching: false, error: null, ok: true }
    },
    editVariableError(state, res) {
      state.editVariableRequest = { fetching: false, error: res.message, ok: false }
    },

    // handle a study's variables
    addVariableToStudyStart(state) {
      state.addVariableToStudyRequest = { fetching: true, error: null, ok: false }
    },
    addVariableToStudySuccess(state, res) {
      state.addVariableToStudyRequest = { fetching: false, error: null, ok: true }
      this.commit('setCurrentStudyVariables', res)
    },
    addVariableToStudyError(state, res) {
      state.addVariableToStudyRequest = { fetching: false, error: res, ok: false }
    },
    removeVariableFromStudyStart(state) {
      state.removeVariableFromStudyRequest = { fetching: true, error: null, ok: false }
    },
    removeVariableFromStudySuccess(state, res) {
      state.removeVariableFromStudyRequest = { fetching: false, error: null, ok: true }
      this.commit('setCurrentStudyVariables', res)
    },
    removeVariableFromStudyError(state, res) {
      state.removeVariableFromStudyRequest = { fetching: false, error: res, ok: false }
    },
  },
  actions: {
    addVariable({ commit }, variable) {
      return LegacyApi.post(
        {
          url: '/api/variable/add',
          body: variable,
          startMut: 'addVariableStart',
          successMut: (variable) => {
            commit('addVariableSuccess')
            commit('setCurrentSearch', variable)
            commit('unmarkSearchEdited')
          },
          errorMut: 'addVariableError',
        },
        commit
      )
    },
    editVariable({ commit }, variable) {
      return LegacyApi.put(
        {
          url: `/api/variable/${variable.id}`,
          body: variable,
          startMut: 'editVariableStart',
          successMut: (variable) => {
            commit('editVariableSuccess')
            commit('setCurrentSearch', variable)
            commit('unmarkSearchEdited')
          },
          errorMut: 'editVariableError',
        },
        commit
      )
    },
    copyVariable({ commit }, variableId) {
      return LegacyApi.get(
        {
          url: `/api/variable/copy/${variableId}`,
          startMut: 'copyVariableStart',
          successMut: 'copyVariableSuccess',
          errorMut: 'copyVariableError',
        },
        commit
      )
    },
    fetchVariable({ commit }, variableId) {
      return LegacyApi.get(
        {
          url: `/api/variable/${variableId}`,
          startMut: 'fetchVariableStart',
          successMut: (variable) => {
            commit('setCurrentSearch', variable)
            commit('fetchVariableSuccess')
          },
          errorMut: () => {
            commit('resetNewSearch')
            commit('fetchVariableError')
          },
        },
        commit
      )
    },
    fetchVariables({ commit, state }, { force, withConditions }) {
      force = Boolean(force)
      // Check if already loaded or loading
      if (!force && (state.fetchVariablesRequest.fetching || state.fetchVariablesRequest.ok)) {
        return null
      }

      return LegacyApi.get(
        {
          url: `/api/variable/?withConditions=${Number(withConditions ?? 0)}`,
          startMut: 'fetchVariablesStart',
          successMut: 'fetchVariablesSuccess',
          errorMut: 'fetchVariablesError',
        },
        commit
      )
    },
    removeVariable({ commit }, variableId) {
      return LegacyApi.del(
        {
          url: `/api/variable/${variableId}`,
          body: '',
          startMut: 'removeVariableStart',
          successMut: () => commit('removeVariableSuccess', variableId),
          errorMut: 'removeVariableError',
        },
        commit
      )
    },
    searchVariable({ commit }, { search, studyId, signal }) {
      const urlParams = { search }
      if (studyId) {
        urlParams.studyId = studyId
      }

      return Api.request(
        new ApiRequest('searchVariable', 'GET', '/api/variable/search-variable'),
        {
          urlParams,
          signal,
          startMut: 'searchVariableStart',
          successMut: 'searchVariableSuccess',
          errorMut: 'searchVariableError',
        },
        commit,
      )
    },
    addVariableToStudy({ commit }, { studyId, variableId }) {
      return LegacyApi.put(
        {
          url: `/api/variable/${variableId}/study/${studyId}`,
          startMut: 'addVariableToStudyStart',
          successMut: 'addVariableToStudySuccess',
          errorMut: 'addVariableToStudyError'
        },
        commit
      )
    },
    removeVariableFromStudy({ commit }, { studyId, variableId }) {
      return LegacyApi.del(
        {
          url: `/api/variable/${variableId}/study/${studyId}`,
          startMut: 'removeVariableFromStudyStart',
          successMut: 'removeVariableFromStudySuccess',
          errorMut: 'removeVariableFromStudyError'
        },
        commit
      )
    },
  },
}
