import { ApiRequest } from '@sancare/ui-frontend-commons/src/misc/api-request'

import { StudyState } from './types'

const baseUrl = '/api/study'

const state = new StudyState()
state.requests = {
  createStudyFromFile: new ApiRequest('createStudyFromFile', 'POST', `${baseUrl}/create-from-file`),
  fetchInvestigators: new ApiRequest('fetchInvestigators', 'GET', `${baseUrl}/investigators`),
}

export { state }
