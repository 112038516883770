import { AbstractState } from '@sancare/ui-frontend-commons/src/store/types'
import { Dayjs } from 'dayjs'

export enum JobType {
  COMPUTE_STUDY = 'compute_study',
}

export const jobTypeLabels = {
  [JobType.COMPUTE_STUDY]: 'Calcul de l\'étude',
}

export enum JobStatus {
  PENDING = 'pending',
  ONGOING = 'ongoing',
  ENDED = 'ended',
  ERROR = 'error',
  CANCELED = 'canceled',
}

export const jobStatusLabels = {
  [JobStatus.PENDING]: 'En attente',
  [JobStatus.ONGOING]: 'En cours',
  [JobStatus.ENDED]: 'Terminée',
  [JobStatus.ERROR]: 'Erreur',
  [JobStatus.CANCELED]: 'Annulée',
}

export type BaseJob<TDate extends Dayjs|string> = {
  id: number
  study: { id: number, name: string }|null
  type: JobType
  status: JobStatus
  createdBy: { id: number, firstName: string, lastName: string }|null
  createdAt: TDate
  startedAt: TDate|null
  endedAt: TDate|null
}

export type Job = BaseJob<Dayjs>

export type RawJob = BaseJob<string>

export class JobState extends AbstractState {
  jobs: Job[] = []
}
