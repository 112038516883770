import Api from '../misc/api'

export default {
  state: {
    setPatientStudyFeedbackRequest: { fetching: false, error: null, ok: false }
  },
  mutations: {
    setPatientStudyFeedbackStart(state) {
      state.setPatientStudyFeedbackRequest = { fetching: true, error: null, ok: false }
    },
    setPatientStudyFeedbackSuccess(state) {
      state.setPatientStudyFeedbackRequest.fetching = false
      state.setPatientStudyFeedbackRequest.ok = true
    },
    setPatientStudyFeedbackError(state, res) {
      state.setPatientStudyFeedbackRequest.fetching = false
      state.setPatientStudyFeedbackRequest.error = res.message
    },
  },
  actions: {
    setPatientStudyFeedback({ commit }, { patientIpp, studyId, feedback }) {
      return Api.post(
        {
          url: `/api/patient-feedback/study/${studyId}`,
          body: { patientIpp, flag: feedback },
          startMut: 'setPatientStudyFeedbackStart',
          successMut: 'setPatientStudyFeedbackSuccess',
          errorMut: 'setPatientStudyFeedbackError'
        },
        commit
      )
    },
  }
}
