import dayjs from '@sancare/ui-frontend-commons/src/misc/dayjs'
import _ from 'lodash'

import { getAppSettings, getSancareSettings } from '@/misc/getSettings'

import Api from '../misc/api'

const appSettingNameList = [
  'minimum_patient_validation',
]
const sancareSettingNameList = [
  'enable_compute_study_jobs',
  'restricted_legal_finess',
  'restricted_geo_finess',
]

export default {
  state: {
    appVersion: window.appVersion,
    snomedServiceEnabled: window.snomedServiceEnabled,
    currentUser: {
      id: null,
      role: null,
      editableRoles: [],
      teamMode: false,
      team: {
        id: null,
      },
    },
    userSettings: {},
    noSearchWarning: dayjs.unix(localStorage.getItem('noSearchWarning') || 0),
    appSettings: _.mapValues(window.appSettings, (settingCategory) => _.pick(settingCategory, appSettingNameList)),
    sancareSettings: _.mapValues(window.appSettings, (settingCategory) => _.pick(settingCategory, sancareSettingNameList)),

    earlyLimit: null,
    lateLimit: null,

    getSelfRequest: { fetching: false, error: null, ok: false },
    updateSelfRequest: { fetching: false, error: null, ok: false },
    setAppSettingsRequest: { fetching: false, error: null, ok: false },
    getUserSettingsRequest: { fetching: false, error: null, ok: false },
    setUserSettingsRequest: { fetching: false, error: null, ok: false },
    sendBugReportRequest: { fetching: false, error: null, ok: false },
    setSancareSettingsRequest: { fetching: false, error: null, ok: false },
  },
  getters: {
    appVersion: (state) => state.appVersion,
    snomedServiceEnabled: (state) => state.snomedServiceEnabled,
    flatAppSettings(state) {
      return getAppSettings(state)
    },
    flatSancareSettings(state) {
      return getSancareSettings(state)
    },
    earlyLimit(state) {
      return state.earlyLimit
    },
    lateLimit(state) {
      return state.lateLimit
    },
  },
  mutations: {
    getSelfStart(state) {
      state.getSelfRequest = { fetching: true, error: null, ok: false }
    },
    getSelfSuccess(state, user) {
      state.getSelfRequest.fetching = false
      state.getSelfRequest.ok = true
      state.currentUser = user
    },
    getSelfError(state, res) {
      state.getSelfRequest.fetching = false
      state.getSelfRequest.error = res.message
    },
    updateSelfStart(state) {
      state.updateSelfRequest = { fetching: true, error: null, ok: false }
    },
    updateSelfSuccess(state, user) {
      state.updateSelfRequest = { fetching: false, error: null, ok: true }
      state.currentUser = user
    },
    updateSelfError(state, res) {
      state.updateSelfRequest = { fetching: false, error: res.message, ok: false }
    },
    resetUpdateSelf(state) {
      state.updateSelfRequest = { fetching: false, error: null, ok: false }
    },
    setAppSettingsStart(state) {
      state.setAppSettingsRequest = { fetching: true, error: null, ok: false }
    },
    setAppSettingsSuccess(state, newSettings) {
      state.setAppSettingsRequest = { fetching: false, error: null, ok: true }
      state.appSettings = _.mapValues(newSettings, (settingCategory) => _.pick(settingCategory, appSettingNameList))
    },
    setSancareSettingsStart(state) {
      state.setSancareSettingsRequest = { fetching: true, error: null, ok: false }
    },
    setSancareSettingsSuccess(state, newSettings) {
      state.setSancareSettingsRequest = { fetching: false, error: null, ok: true }
      state.sancareSettings = _.mapValues(newSettings, (settingCategory) => _.pick(settingCategory, sancareSettingNameList))
    },
    setSancareSettingsError(state, res) {
      state.setSancareSettingsRequest = { fetching: false, error: res.message, ok: false }
    },
    setAppSettingsError(state, res) {
      state.setAppSettingsRequest = { fetching: false, error: res.message, ok: false }
    },
    setNoSearchWarning(state, noSearchWarning) {
      localStorage.setItem('noSearchWarning', noSearchWarning.unix())
      state.noSearchWarning = noSearchWarning
    },
    getUserSettingsStart(state) {
      state.getUserSettingsRequest = { fetching: true, error: null, ok: false }
    },
    getUserSettingsSuccess(state, userSettings) {
      state.getUserSettingsRequest = { fetching: false, error: null, ok: true }
      state.userSettings = { ...state.userSettings }

      _.forEach(userSettings, (setting) => {
        state.userSettings[setting.name] = setting.value
      })
    },
    getUserSettingsError(state, res) {
      state.getUserSettingsRequest = { fetching: false, error: res.message, ok: false }
    },
    setUserSettingsStart(state) {
      state.setUserSettingsRequest = { fetching: true, error: null, ok: false }
    },
    setUserSettingsSuccess(state, userSettings) {
      state.setUserSettingsRequest = { fetching: false, error: null, ok: true }
      state.userSettings = { ...state.userSettings }

      _.forEach(userSettings, (setting) => {
        state.userSettings[setting.name] = setting.value
      })
    },
    setUserSettingsError(state, res) {
      state.setUserSettingsRequest = { fetching: false, error: res.message, ok: false }
    },
    sendBugReportStart(state) {
      state.sendBugReportRequest = { fetching: true, error: null, ok: false }
    },
    sendBugReportSuccess(state) {
      state.sendBugReportRequest = { fetching: false, error: null, ok: true }
    },
    sendBugReportError(state, res) {
      state.sendBugReportRequest = { fetching: false, error: res.message, ok: false }
    },
    setEarlyLimit(state, lim) {
      state.earlyLimit = lim
      sessionStorage.setItem('earlyLimit', lim.format())
    },
    setLateLimit(state, lim) {
      state.lateLimit = lim
      sessionStorage.setItem('lateLimit', lim.format())
    },
  },
  actions: {
    getSelf({ commit }) {
      return Api.get(
        {
          url: '/api/user/me',
          startMut: 'getSelfStart',
          successMut: 'getSelfSuccess',
          errorMut: 'getSelfError',
        },
        commit
      )
    },
    updateSelf({ commit }, data) {
      return Api.put(
        {
          url: '/api/user/me',
          body: data,
          startMut: 'updateSelfStart',
          successMut: 'updateSelfSuccess',
          errorMut: 'updateSelfError',
        },
        commit
      )
    },
    setAppSettings({ commit }, settings) {
      return Api.put(
        {
          url: '/api/settings/app-settings',
          body: { ...settings },
          startMut: 'setAppSettingsStart',
          successMut: 'setAppSettingsSuccess',
          errorMut: 'setAppSettingsError',
        },
        commit
      )
    },
    setSancareSettings({ commit }, settings) {
      return Api.put(
        {
          url: '/api/settings/sancare-settings',
          body: { ...settings },
          startMut: 'setSancareSettingsStart',
          successMut: 'setSancareSettingsSuccess',
          errorMut: 'setSancareSettingsError',
        },
        commit
      )
    },
    getUserSettings({ commit }) {
      return Api.get(
        {
          url: '/api/settings/user-settings',
          startMut: 'getUserSettingsStart',
          successMut: 'getUserSettingsSuccess',
          errorMut: 'getUserSettingsError',
        },
        commit
      )
    },
    setUserSettings({ commit }, userSettings) {
      return Api.post(
        {
          url: '/api/settings/user-settings',
          body: userSettings,
          startMut: 'setUserSettingsStart',
          successMut: 'setUserSettingsSuccess',
          errorMut: 'setUserSettingsError',
        },
        commit
      )
    },
    sendBugReport({ commit }, bugInfo) {
      return Api.post(
        {
          url: '/api/settings/bug-report',
          body: bugInfo,
          startMut: 'sendBugReportStart',
          successMut: 'sendBugReportSuccess',
          errorMut: 'sendBugReportError',
        },
        commit
      )
    },
  },
}
