import dayjs from 'dayjs'

import { Job, RawJob } from '@/store/modules/job/types'

export function formatJob(job: RawJob): Job {
  return Object.assign(job, {
    createdAt: dayjs(job.createdAt),
    startedAt: job.startedAt ? dayjs(job.startedAt) : null,
    endedAt: job.endedAt ? dayjs(job.endedAt) : null,
  })
}
