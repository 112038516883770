<script>
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'

import HomeList from './HomeList.vue'

export default {
  components: {
    'home-list': HomeList
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      finessLimitList(state) {
        return _.concat(
          state.settings.currentUser.legalEntities || [],
          state.settings.currentUser.geographicEntities || []
        )
      }
    }),
    ...mapGetters(['userRole']),
    teamMode() {
      return this.$store.state.settings.currentUser.teamMode
    },
  },
  mounted() {
    const urlParams = this.$store.state.route.query
    this.refresh()
    this.$store.commit('initPatientList', urlParams)
    this.$store.commit('setBreadcrumbShow', false)
  },
  methods: {
    refreshGlobalStats() {
      this.$store.dispatch('getHomeStats', {
        earlyLimit: null,
        lateLimit: null,
      })
    },
    refreshStudies() {
      this.$store.dispatch('getStudies')
    },
    refresh() {
      this.refreshGlobalStats()
      this.refreshStudies()
      this.$store.commit('setBreadcrumbList', { breadcrumbList: [] })
    },
    setList(mode) {
      this.mode = mode
    },
    getFinessTooltip(finessLimit) {
      return `${finessLimit.longDescription && finessLimit.longDescription.length > 0 ? finessLimit.longDescription : finessLimit.shortDescription} (${finessLimit.finess})`
    }
  }
}
</script>

<template>
  <div
    name="homepage"
    class="large-container"
  >
    <div
      v-if="finessLimitList && finessLimitList.length"
      class="alert alert-secondary"
    >
      Votre compte est restreint aux établissements suivants:
      <ul class="mb-0">
        <li
          v-for="finessLimit in finessLimitList"
          :key="finessLimit.finess"
        >
          <span
            v-tooltip="getFinessTooltip(finessLimit)"
          >
            {{ finessLimit.shortDescription }}
          </span>
        </li>
      </ul>
    </div>
    <div class="home-list mb-2">
      <home-list />
    </div>
  </div>
</template>
