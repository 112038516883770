import { ApiRequest } from '@sancare/ui-frontend-commons/src/misc/api-request'

import { PatientState } from './types'

const baseUrl = '/api/patients'

const state = new PatientState()
state.requests = {
  getOnePatient: new ApiRequest('getOnePatient', 'GET', `${baseUrl}/{ipp}`)
}

export { state }
