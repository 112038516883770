let timeoutHnd = null

export const actions = {
  setError({ commit }, message: string, timeout: number|null = null) {
    commit('setError', message)
    if (timeout !== null) {
      setTimeout(() => {
        commit('resetToast')
      }, timeout)
    }
  },
  setSuccess({ commit }, message: string, timeout: number|null = 5000) {
    if (timeoutHnd) {
      clearTimeout(timeoutHnd)
    }
    commit('setSuccess', message)
    if (timeout !== null) {
      timeoutHnd = setTimeout(() => {
        commit('resetToast')
      }, timeout)
    }
  },
}
