/**
 * todo move in frontend-commons
 *
 * takes an object of various typed parameters,
 * filters out null values
 * then returns an URI encoded string
 *
 * caution: we do not use URL.searchParam.append because it does not encode well params. See https://stackoverflow.com/questions/59889140/different-output-from-encodeuricomponent-vs-urlsearchparams
 */
export function buildUrlWithParams(url: string, params: { [index: string]: string|number|(string|number)[] }): string {
  const urlParams = []
  Object.keys(params).forEach((paramKey) => {
    const paramValue = params[paramKey]
    if (paramValue === null || paramValue === '') {
      return
    }
    if (Array.isArray(paramValue)) {
      paramValue.forEach((value) => urlParams.push(`${paramKey}[]=${encodeURIComponent(value)}`))
      return
    }
    urlParams.push(`${paramKey}=${encodeURIComponent(paramValue)}`)
  })

  return url.concat(urlParams.length ? `?${urlParams.join('&')}` : '')
}
