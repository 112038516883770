<script>
import { SancareOcticon } from '@sancare/ui-frontend-commons'
import { mapState } from 'vuex'

export default {
  components: {
    'sancare-octicon': SancareOcticon,
  },
  data() {
    return {
      showSettings: false,
      formData: {
        oldPassword: '',
        password: '',
        passwordConfirmation: '',
        firstName: '',
        lastName: '',
      },
      formErrors: {
        passwordMismatch: false,
        passwordChars: false,
        passwordTooShort: false,
      },
    }
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.settings.currentUser,
      updateSelfRequest: (state) => state.settings.updateSelfRequest,
    }),
    teamMode: {
      get() {
        return this.$store.state.settings.currentUser.teamMode
      },
      set(teamMode) {
        this.teamModeUpdate = true
        this.$store.dispatch('updateSelf', { teamMode })
      },
    }
  },
  watch: {
    showSettings(visible) {
      if (visible) {
        document.body.classList.add('modal-open')
      } else {
        document.body.classList.remove('modal-open')
      }
    },
    updateSelfRequest(req) {
      if (!req.ok) {
        return
      }
      if (this.teamModeUpdate) {
        this.teamModeUpdate = false

        return
      }
      this.closeModal()
    },
    'formData.password': function(np) {
      if (this.formErrors.passwordTooShort && np.length >= 8) {
        this.formErrors.passwordTooShort = false
      }
      if (this.formErrors.passwordMismatch && np === this.formData.passwordConfirmation) {
        this.formErrors.passwordMismatch = false
      }
      if (this.formErrors.passwordChars && np.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/)) {
        this.formErrors.passwordChars = false
      }
    },
    'formData.passwordConfirmation': function(npc) {
      if (this.formErrors.passwordMismatch && this.formData.password === npc) {
        this.formErrors.passwordMismatch = false
      }
    },
    currentUser(newUser) {
      this.formData.firstName = newUser.firstName
      this.formData.lastName = newUser.lastName

    },
  },
  methods: {
    closeModal() {
      this.showSettings = false
      this.formData = {
        oldPassword: '',
        password: '',
        passwordConfirmation: '',
        firstName: this.currentUser.firstName,
        lastName: this.currentUser.lastName,
      }
      this.formErrors = { passwordTooShort: false, passwordMismatch: false }
      this.$store.commit('resetUpdateSelf')
    },
    openModal() {
      this.showSettings = true
    },
    checkPasswords() {
      this.formErrors.passwordTooShort = this.formData.password.length < 8
      this.formErrors.passwordChars = !this.formData.password.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/)
      if (this.formData.password && this.formData.passwordConfirmation && this.formData.password !== this.formData.passwordConfirmation) {
        this.formErrors.passwordMismatch = true
      }
    },
    handleSubmitPassword() {
      this.checkPasswords()
      if (this.formErrors.passwordTooShort || this.formErrors.passwordChars || this.formErrors.passwordMismatch) {
        return
      }

      const payload = {
        password: this.formData.password,
        oldPassword: this.formData.oldPassword,
      }
      this.$store.dispatch('updateSelf', payload)
    },
    handleSubmitNames() {
      const payload = {}
      if (this.formData.firstName !== this.currentUser.firstName) {
        payload.firstName = this.formData.firstName
      }
      if (this.formData.lastName !== this.currentUser.lastName) {
        payload.lastName = this.formData.lastName
      }
      this.$store.dispatch('updateSelf', payload)
    },
  },
}

</script>

<template>
  <div>
    <div
      v-tooltip="'Paramètres'"
      class="px-2 rotate"
      @click="openModal()"
    >
      <sancare-octicon
        name="gear"
        :width="25"
        :height="25"
      />
    </div>
    <transition name="sancare-modal">
      <div
        v-if="showSettings"
        class="sancare-modal-mask row no-gutters justify-content-center align-items-start"
        @click.stop="closeModal()"
      >
        <div
          class="sancare-modal-overlay col-10 col-md-7 col-lg-5 col-xl-4"
          @click.stop
        >
          <div class="sancare-modal-header">
            <span class="row">
              <span
                class="sancare-modal-icon col-1 col-md-1 col-lg-1 col-xl-1"
              >
                <sancare-octicon
                  name="gear"
                  :width="18"
                  :height="26"
                />
              </span>
              <span class="sancare-modal-title col-9 col-md-9 col-lg-9 col-xl-9">Paramètres</span>
              <span
                class="sancare-modal-close col-2 col-md-2 col-lg-2 col-xl-2"
                @click="closeModal()"
              >
                <sancare-octicon
                  name="x"
                  :width="20"
                  :height="20"
                />
              </span>
            </span>
          </div>
          <div class="sancare-modal-body">
            <form
              class="row"
              @submit.prevent="handleSubmitNames"
            >
              <div class="form-group col-12 col-md-6">
                <label
                  for="first-name"
                  name="first-name"
                  class="small-label"
                >
                  Prénom
                </label>
                <input
                  id="first-name"
                  v-model="formData.firstName"
                  class="form-control"
                >
              </div>
              <div class="form-group col-12 col-md-6">
                <label
                  for="last-name"
                  name="last-name"
                  class="small-label"
                >
                  Nom de Famille
                </label>
                <input
                  id="last-name"
                  v-model="formData.lastName"
                  class="form-control"
                >
              </div>
              <div class="col-12 text-right">
                <button
                  class="btn btn-push btn-primary btn-padded"
                  type="submit"
                >
                  Modifier mes informations
                </button>
              </div>
            </form>
            <hr v-if="currentUser.createdFromLdap === false">
            <form
              v-if="currentUser.createdFromLdap === false"
              class="row"
              @submit.prevent="handleSubmitPassword"
            >
              <div class="form-group col-6">
                <label
                  for="old-password"
                  name="old-password"
                  class="small-label"
                >
                  Ancien mot de passe
                </label>
                <input
                  id="old-password"
                  v-model="formData.oldPassword"
                  class="form-control"
                  type="password"
                >
              </div>
              <div class="col-6">
&nbsp;
              </div>
              <div class="form-group col-12 col-md-6">
                <label
                  for="password"
                  name="password"
                  class="small-label"
                >
                  Nouveau mot de passe
                </label>
                <input
                  id="password"
                  v-model="formData.password"
                  :class="{ 'is-invalid': formErrors.passwordTooShort || formErrors.passwordChars }"
                  class="form-control"
                  type="password"
                  @blur="checkPasswords"
                >
                <div class="invalid-feedback">
                  <div v-if="formErrors.passwordTooShort">
                    Ce mot de passe est trop court.
                  </div>
                  <div v-if="formErrors.passwordChars">
                    Le mot de passe doit contenir au moins une minuscule, une majuscule et un chiffre.
                  </div>
                </div>
              </div>
              <div class="form-group col-12 col-md-6">
                <label
                  for="passwordConfirmation"
                  name="password-confirm"
                  class="small-label"
                >
                  Confirmation
                </label>
                <input
                  id="passwordConfirmation"
                  v-model="formData.passwordConfirmation"
                  :class="{ 'is-invalid': formErrors.passwordMismatch }"
                  class="form-control"
                  type="password"
                  @blur="checkPasswords"
                >
                <div class="invalid-feedback">
                  Les mots de passe sont différents
                </div>
              </div>
              <div class="col-12 text-right">
                <button
                  class="btn btn-push btn-primary btn-padded"
                  type="submit"
                >
                  Modifier mon mot de passe
                </button>
              </div>
            </form>
            <div
              v-if="updateSelfRequest.error"
              class="text-danger"
            >
              {{ updateSelfRequest.error }}
            </div>
            <hr v-if="currentUser.team !== null">
            <div
              v-if="currentUser.team !== null"
              class="row col-12 col-md-12"
              style="justify-content: center"
            >
              <!--<v-dropdown
                trigger="hover"
                placement="top"
                popover-class="onmodal"
                style="margin: 0;padding: 0;margin-bottom: 5px;"
              >
                <span>
                  <sancare-octicon
                    name="question"
                    :width="16"
                    :height="30"
                  />
                </span>
                <slot />
                <template #popper>
                  <div style="z-index: 10000">
                    Affiche ou non les critères d'équipe pour votre compte
                  </div>
                </template>
              </v-dropdown>
              <label
                class="col-auto toggle-switch"
                for="inTeamModeBool"
                style="align-self: center;margin: 0;"
              >
                Mode travail en équipe
              </label>
              <input
                id="inTeamModeBool"
                v-model="teamMode"
                type="checkbox"
                name="teamMode"
                style="margin: 0;"
              >-->
            </div>
          </div>
          <div class="sancare-modal-footer" />
        </div>
      </div>
    </transition>
  </div>
</template>
