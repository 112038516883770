import {
  BaseChunk,
  BaseTextualHealthEntry,
} from '@sancare/ui-frontend-commons/src/types/health-data'

export enum TextualHealthEntryType {
  medicalHistory = 'Antécédent',
  nurseReport = 'Dossier infirmier',
  labResultComment = 'Commentaire de résultats',
  targetNurseReport = 'Transmission ciblée',
  surgeryLabel = 'Libellé de chirurgie',
}

export interface Chunk extends BaseChunk {
  studyNodeIds: number[]
}

export interface HealthData {
  id: number
  creationDate: Date
}

export interface Report extends HealthData {
  content: string
  documentTitle: string
  documentTemplate: string
  markedAntecedentIndexSets: Array<Array<number>>
  markedFamilyAntecedentIndexSets: Array<Array<number>>
  markedDoubtIndexSets: Array<Array<number>>
  markedNegationIndexSets: Array<Array<number>>
  chunks?: Chunk[]
}

export type TextualHealthEntry = BaseTextualHealthEntry<Chunk>

export interface CategoricalLabResult extends HealthData {
  code: string
  conclusion: string
  title: string
  value: string
  matchOrigins?: Array<boolean>
}

export interface LabResult extends HealthData {
  code: string
  conclusion: string
  title: string
  unit: string
  value: number
  matchOrigins?: Array<boolean>
}

export interface HealthConstantType extends HealthData {
  description: string
}

export interface HealthConstant extends HealthData {
  constantType: HealthConstantType
  value: number
  matchOrigins?: Array<boolean>
}
