import { SettingDataGroups } from '@/store/modules/settings/types'

export default {
  global: {
    label: 'Paramètres généraux',
    settings: [
      {
        name: 'minimum_patient_validation',
        label: 'Nombre minimum de validations pour accepter un patient dans une étude',
        type: 'slider',
      },
    ],
  },
} as SettingDataGroups
