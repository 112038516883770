import { buildUrlWithParams } from '@/misc/urlUtils'

import Api from '../misc/api'

const DEFAULT_PATIENTLIST_PAGE_SIZE = 20
const DEFAULT_PATIENTLIST_PAGE = 1
const DEFAULT_PATIENTLIST_ORDER = 'id'

function resetReadingList() {
  return {
    currentIndex: null,
    readingListCount: 0,
    previous: null,
    current: null,
    next: null,
    status: null,
  }
}

function getPatientListParams(state, rootState) {
  return {
    ...state.patientListParams,
    pageSize: parseInt(state.patientListParams.pageSize),
    earlyLimit: rootState.settings.earlyLimit !== null ? rootState.settings.earlyLimit.format('YYYY-MM-DD') : null,
    lateLimit: rootState.settings.lateLimit !== null ? rootState.settings.lateLimit.format('YYYY-MM-DD') : null,
  }
}

export default {
  state: {
    patientListRoute: null,
    patientListParams: {
      currentPage: DEFAULT_PATIENTLIST_PAGE,
      order: DEFAULT_PATIENTLIST_ORDER,
      reverseOrder: false,
      pageSize: DEFAULT_PATIENTLIST_PAGE_SIZE,
    },
    patientList: [],
    patientCount: 0,
    pageCount: 1,
    readingList: resetReadingList(),
    selectedPatients: [],
    ReadingListQuery: {},

    fetchPatientListRequest: { fetching: false, error: null, ok: false },
    countPatientSearchRequest: { fetching: false, error: null, ok: false },
    createReadingListRequest: { fetching: false, error: null, ok: false },
    getCurrentReadingListRequest: { fetching: false, error: null, ok: false },
    updateReadingListRequest: { fetching: false, error: null, ok: false },
  },
  getters: {
    shortHandPatientListParams(state) {
      return {
        od: (state.patientListParams.order !== DEFAULT_PATIENTLIST_ORDER) ? state.patientListParams.order : undefined,
        ro: state.patientListParams.reverseOrder ? true : undefined,
        cp: (state.patientListParams.currentPage !== DEFAULT_PATIENTLIST_PAGE) ? state.patientListParams.currentPage : undefined,
        ps: (state.patientListParams.pageSize !== DEFAULT_PATIENTLIST_PAGE_SIZE) ? state.patientListParams.pageSize : undefined,
      }
    },
  },
  mutations: {
    initPatientList(state, routeQuery) {
      const params = {
        order: routeQuery.od ? routeQuery.od : DEFAULT_PATIENTLIST_ORDER,
        reverseOrder: routeQuery.ro ? true : false,
        currentPage: routeQuery.cp ? parseInt(routeQuery.cp) : DEFAULT_PATIENTLIST_PAGE,
        pageSize: routeQuery.ps ? parseInt(routeQuery.ps) : DEFAULT_PATIENTLIST_PAGE_SIZE,
      }

      state.patientListParams = params
      state.patientList = []
      state.patientCount = 0
      state.pageCount = 1
    },
    setPatientListRoute(state, route) {
      state.patientListRoute = route
    },
    setPatientListParam(state, { type, value }) {
      state.patientListParams[type] = value
    },
    fetchPatientListStart(state) {
      state.fetchPatientListRequest = { fetching: true, error: null, ok: false }
    },
    fetchPatientListSuccess(state, response) {
      state.fetchPatientListRequest = { fetching: false, error: null, ok: true }
      state.patientList = response.content
      state.patientCount = response._hateoas.filteredItemCount
      state.pageCount = response._hateoas.pageCount
    },
    fetchPatientListError(state, res) {
      state.fetchPatientListRequest = { fetching: false, error: res.message, ok: false }
      state.patientList = []
      state.patientCount = 0
      state.pageCount = 1
    },
    countPatientSearchStart(state) {
      state.countPatientSearchRequest = { fetching: true, error: null, ok: false }
    },
    countPatientSearchSuccess(state, patientCount) {
      state.countPatientSearchRequest = { fetching: false, error: null, ok: true }
      state.patientCount = patientCount
    },
    countPatientSearchError(state, res) {
      state.countPatientSearchRequest = { fetching: false, error: res.message, ok: false }
      state.patientCount = 0
      state.pageCount = 1
    },
    resetPatientSelection(state) {
      state.selectedPatients = []
    },
    toggleSelectedPatient(state, patient) {
      const patientIdx = state.selectedPatients.findIndex((s) => s.ipp === patient.ipp)
      if (patientIdx === -1) {
        state.selectedPatients.push({ ipp: patient.ipp })
      } else {
        state.selectedPatients.splice(patientIdx, 1)
      }
    },
    resetReadingList(state) {
      state.readingList = resetReadingList()
    },
    createReadingListStart(state) {
      state.createReadingListRequest = { fetching: true, error: null, ok: false }
    },
    createReadingListSuccess(state, res) {
      state.createReadingListRequest.fetching = false
      state.createReadingListRequest.ok = true
      state.readingList = res
    },
    createReadingListError(state, res) {
      state.createReadingListRequest.fetching = false
      state.createReadingListRequest.error = res.message
      state.readingList = resetReadingList()
    },
    updateReadingListStart(state) {
      state.updateReadingListRequest = { fetching: true, error: null, ok: false }
    },
    updateReadingListSuccess(state, res) {
      state.updateReadingListRequest.fetching = false
      state.updateReadingListRequest.ok = true
      state.readingList = res
    },
    updateReadingListError(state, res) {
      state.updateReadingListRequest.fetching = false
      state.updateReadingListRequest.error = res.message
      state.readingList = resetReadingList()
    },
    getCurrentReadingListStart(state) {
      state.getCurrentReadingListRequest = { fetching: true, error: null, ok: false }
    },
    getCurrentReadingListSuccess(state, res) {
      state.getCurrentReadingListRequest.fetching = false
      state.getCurrentReadingListRequest.ok = true
      state.readingList = res
    },
    getCurrentReadingListError(state, res) {
      state.getCurrentReadingListRequest.fetching = false
      state.getCurrentReadingListRequest.error = res.message
      state.readingList = resetReadingList()
    },
  },
  actions: {
    saveColumnSelection({ commit }, columnList) {
      return Api.post(
        {
          url: '/api/patients/column-selection',
          body: columnList,
          startMut: 'saveColumnSelectionStart',
          successMut: 'saveColumnSelectionSuccess',
          errorMut: 'savedColumnSelectionError',
        },
        commit
      )
    },
    fetchPatientList({ commit, state, rootState }, resetPatientSelection) {
      if (resetPatientSelection) {
        commit('resetPatientSelection')
      }
      const params = getPatientListParams(state, rootState)

      return Api.get(
        {
          url: buildUrlWithParams('/api/patients/patient-list', params),
          startMut: 'fetchPatientListStart',
          successMut: (response) => {
            commit('fetchPatientListSuccess', response)
          },
          errorMut: 'fetchPatientListError',
        },
        commit
      )
    },
    fetchStudyPatientList({ commit, state }, { study, columns }) {
      const params = {
        ...state.patientListParams,
        pageSize: parseInt(state.patientListParams.pageSize),
      }
      Object.keys(columns).forEach((column) => {
        const varType = columns[column].type
        if (!params[varType]) {
          params[varType] = []
        }
        params[varType].push(columns[column].id ?? columns[column].name)
      })

      return Api.get({
        url: buildUrlWithParams(`/api/patients/study/${study.id}`, params),
        startMut: 'fetchPatientListStart',
        successMut: 'fetchPatientListSuccess',
        errorMut: 'fetchPatientListError',
      }, commit)
    },
    fetchStepPatientList({ commit, state }, stepId) {
      const params = {
        ...state.patientListParams,
        pageSize: parseInt(state.patientListParams.pageSize),
      }

      return Api.get({
        url: buildUrlWithParams(`/api/patients/step/${stepId}`, params),
        startMut: 'fetchPatientListStart',
        successMut: (response) => {
          commit('fetchPatientListSuccess', response)
        },
        errorMut: 'fetchPatientListError',
      }, commit)
    },
    searchPatients({ commit, state, rootState }, stepId = null) {
      const params = getPatientListParams(state, rootState)
      const url = stepId === null ? '/api/patients/search' : `/api/patients/search/${stepId}`

      return Api.post({
        url: url,
        body: { ...rootState.search.currentSearch, ...params },
        startMut: 'fetchPatientListStart',
        successMut: (response) => {
          commit('fetchPatientListSuccess', response)
        },
        errorMut: 'fetchPatientListError',
      }, commit)
    },
    countPatientSearch({ commit, state, rootState }, stepId = null) {
      const params = getPatientListParams(state, rootState)
      const url = stepId === null ? '/api/patients/search-count' : `/api/patients/search-count/${stepId}`

      return Api.post({
        url: url,
        body: { ...rootState.search.currentSearch, ...params },
        startMut: 'countPatientSearchStart',
        successMut: (response) => {
          commit('countPatientSearchSuccess', response)
        },
        errorMut: 'countPatientSearchError',
      }, commit)
    },
    distributePatients({ commit, state, rootState }, { stepId = null, variable }) {
      const params = getPatientListParams(state, rootState)
      const url = stepId === null ? '/api/patients/distribution' : `/api/patients/distribution/${stepId}`

      return Api.post({
        url: url,
        body: { ...variable, ...params },
        startMut: 'fetchPatientListStart',
        successMut: (response) => {
          commit('fetchPatientListSuccess', response)
        },
        errorMut: 'fetchPatientListError',
      }, commit)
    },
    createReadingList({ commit, state }, { query, initIndex, sample }) {
      const body = { ...state.patientListParams, initIndex, query }
      if (sample) {
        body.sample = sample
      }
      return Api.post(
        {
          url: '/api/reading-list',
          body,
          startMut: 'createReadingListStart',
          successMut: 'createReadingListSuccess',
          errorMut: 'createReadingListError',
        },
        commit
      )
    },
    navigateReadingList({ commit }, newIndex) {
      return Api.post(
        {
          url: `/api/reading-list/status?newIndex=${newIndex}`,
          body: '',
          startMut: 'updateReadingListStart',
          successMut: 'updateReadingListSuccess',
          errorMut: 'updateReadingListError',
        },
        commit
      )
    },
    getCurrentReadingList({ commit }) {
      return Api.get(
        {
          url: '/api/reading-list/status',
          startMut: 'getCurrentReadingListStart',
          successMut: 'getCurrentReadingListSuccess',
          errorMut: 'getCurrentReadingListError',
        },
        commit
      )
    },
  },
}
