import Api from '@sancare/ui-frontend-commons/src/misc/api'

import { state } from './state'

export const actions = {
  createStudyComputeJob({ commit }, studyId: number) {
    return Api.request(state.requests.createStudyComputeJob, {
      urlParams: { studyId }
    }, commit)
  },
  fetchJobs({ commit }, studyId: number = null) {
    return Api.request(state.requests.fetchJobs, {
      successMut: 'setJobs',
      urlParams: studyId !== null ? { studyIds: studyId } : {}
    }, commit)
  },
}
