import * as joint from 'jointjs'
import _ from 'lodash'

import { Link, Search, Step, StepLink, StudyNode } from './sancareNamespace'

// used for auto position
const paperPaddingBottom = 250

const jointNamespace = joint.shapes
Object.assign(jointNamespace, {
  sancare: { Link, Step, StepLink, StudyNode, Search }
})

const Diagram = function(element, commit, dispatch) {
  const container = element
  const graph = new joint.dia.Graph({}, { cellNamespace: jointNamespace })
  const paper = new joint.dia.Paper({
    el: container,
    model: graph,
    width: container.clientWidth,
    cellViewNamespace: jointNamespace,
    preventContextMenu: false,
  })
  /**
   * @type {[{element: HTMLElement, id: Number, shape: joint.dia.Cell}]}
   */
  let steps = []
  let studyNodes = []
  let selectedCell
  let stepLinks = []
  let readonly = true


  // private functions
  function setElementPosition(element, position) {
    element.style.top = `${String(position.y)}px`
    element.style.left = `${String(position.x)}px`
  }
  function syncElementPosition(shape, html) {
    const scale = paper.scale()
    const origin = paper.translate()
    // shape origin position is in the middle of the shape (not at the top left corner as usual)
    const offsetX = (scale.sx-1)*shape.attributes.size.width/2
    const offsetY = (scale.sy-1)*shape.attributes.size.height/2
    const relativePosition = {
      x: shape.attributes.position.x*scale.sx+origin.tx+offsetX,
      y: shape.attributes.position.y*scale.sy+origin.ty+offsetY,
    }
    setElementPosition(html, relativePosition)
  }
  function syncElementSize(shape, element) {
    const scale = paper.scale().sx
    element.style.transform = `scale(${scale})`
    shape.resize(element.clientWidth, element.clientHeight)
  }
  function createLink(source, target, isStepLink) {
    const link = isStepLink ? new StepLink() : new Link()
    link.source(source.shape)
    link.target(target.shape)
    link.connector('smooth')
    link.addTo(graph)
    return link
  }

  // boolean to catch if ser is moving an element so we update the diagram on mouseUp
  let movingStepId = null
  let movingStudyNodeId = null

  // events
  // handle drag & drop elements
  graph.on('change:position', function(cell) {
    let el
    switch (cell.attributes.type) {
      case 'sancare.Step':
        el = steps.find((s) => s.id === cell.attributes.stepId)
        if (!readonly) {
          commit('setStepPosition', { id: el.id, ...cell.attributes.position })
        }
        movingStepId = el.id
        break
      case 'sancare.StudyNode':
        el = studyNodes.find((o) => o.id === cell.attributes.studyNodeId)
        if (!readonly) {
          commit('setStudyNodePosition', { id: el.id, ...cell.attributes.position })
        }
        movingStudyNodeId = el.id
        break
    }
    if (el) {
      syncElementPosition(el.shape, el.element)
    }
  })

  // handle elements selection
  paper.on('cell:pointerclick', function(cell) {
    let el
    switch (cell.model.attributes.type) {
      case 'sancare.Step':
        el = steps.find((s) => s.id === cell.model.attributes.stepId)
        break
      case 'sancare.StudyNode':
        el = studyNodes.find((o) => o.id === cell.model.attributes.studyNodeId)
        break
    }
    if (selectedCell) {
      selectedCell.element.classList.remove('selected')
    }
    if (el) {
      selectedCell = el
      el.element.classList.add('selected')
      commit('selectElement', {
        elType: cell.model.attributes.type,
        elId: el.id,
      })
    } else {
      commit('selectElement', { elType: null, elId: null })
    }
  })
  paper.on('blank:pointerclick link:pointerclick', function() {
    if (selectedCell) {
      selectedCell.element.classList.remove('selected')
      commit('selectElement', { elType: null, elId: null })
    }
  })

  // handle moving in paper
  let dragStartPosition = null
  const mouveFunc = function(evt) {
    if (dragStartPosition) {
      const diffX = evt.offsetX - dragStartPosition.x
      const diffY = evt.offsetY - dragStartPosition.y
      paper.translate(diffX, diffY)
      _.forEach(_.concat(steps, studyNodes), (el) => {
        syncElementPosition(el.shape, el.element)
      })
    }
  }
  paper.on('blank:pointerdown', function(event, x, y) {
    const scale = paper.scale()
    dragStartPosition = { x: x*scale.sx, y: y*scale.sy }
    container.addEventListener('mousemove', mouveFunc)
  })
  paper.on('blank:pointerup cell:pointerup', function() {
    dragStartPosition = null
    container.removeEventListener('mousemove', mouveFunc)
  })

  // update study whenever a cell has been moved
  paper.on('cell:pointerup', function() {
    if (movingStepId) {
      if (!readonly) {
        dispatch('updateStepAttributesById', movingStepId)
      }
      movingStepId = null
    }
    if (movingStudyNodeId) {
      if (!readonly) {
        dispatch('updateStudyNodeAttributesById', movingStudyNodeId)
      }
      movingStudyNodeId = null
    }
  })

  // force blur
  paper.on('blank:pointerdown cell:pointerdown', function() {
    document.activeElement.blur()
  })


  // public functions
  this.getPaperSize = () => {
    return paper.getComputedSize()
  }
  this.scale = (coef) => {
    const currentCoef = paper.scale().sx
    const newCoef = currentCoef*coef
    paper.scale(newCoef)
    _.forEach(_.concat(steps, studyNodes), (el) => {
      syncElementPosition(el.shape, el.element)
      syncElementSize(el.shape, el.element)
    })
  }

  this.addStudyNode = (element, studyNode) => {
    const shape = new StudyNode()
    const position = { x: studyNode.diagramProperties.x, y: studyNode.diagramProperties.y }
    shape.position(position.x, position.y)
    syncElementPosition(shape, element)
    syncElementSize(shape, element)
    shape.prop('studyNodeId', studyNode.id)
    shape.addTo(graph)

    studyNodes.push({
      element: element,
      shape: shape,
      id: studyNode.id,
    })
  }
  this.addStep = (element, step) => {
    const shape = new Step()
    const position = {
      x: step.rootNode.diagramProperties.x,
      y: step.rootNode.diagramProperties.y,
    }
    shape.position(position.x, position.y)
    syncElementPosition(shape, element)
    syncElementSize(shape, element)
    shape.prop('stepId', step.id)
    shape.addTo(graph)

    steps.push({
      element,
      shape,
      id: step.id,
    })

    paper.setDimensions(container.clientWidth, step.rootNode.diagramProperties.y + paperPaddingBottom)
  }

  this.createChildrenLinks = (parent, studyNodeChildren) => {
    const source = (parent.children ? studyNodes : steps).find((c) => c.id === parent.id)
    _.forEach(studyNodeChildren, (child) => {
      if (!child) {
        return
      }
      const target = studyNodes.find((c) => c.id === child.id)
      if (target) {
        if (source) {
          createLink(source, target)
        }
        if (child.children) {
          this.createChildrenLinks(child, child.children)
        }
      }
    })
  }
  this.initStepLinks = (step) => {
    this.createChildrenLinks(step, step.rootNode.children)
  }

  this.addStudyNodeLink = (parent, targetstudyNode) => {
    const source = (parent.children ? studyNodes : steps).find((c) => c.id === parent.id)
    const target = studyNodes.find((o) => o.id === targetstudyNode.id)
    if (source && target) {
      createLink(source, target)
    }
  }
  this.addStepLink = (sourceStep, targetStep) => {
    const source = steps.find((step) => step.id === sourceStep.id)
    const target = steps.find((step) => step.id === targetStep.id)
    if (source && target) {
      stepLinks.push(createLink(source, target, true))
    }
  }

  this.setNodeId = (oldId, newId) => {
    const c = studyNodes.find((n) => n.id === oldId)
    c.id = newId
  }
  this.setStepId = (oldId, newId) => {
    const s = steps.find((s) => s.id === oldId)
    s.id = newId
  }

  this.refreshStep = (stepData) => {
    const step = steps.find((s) => s.id === stepData.id)
    if (step) {
      step.shape.resize(step.element.clientWidth, step.element.clientHeight)
    }
  }

  this.removeStep = (stepData) => {
    const step = steps.find((s) => s.id === stepData.id)
    if (step) {
      if (step.element.classList.contains('selected')) {
        commit('selectElement', { elType: null, elId: null })
      }
      step.shape.remove()
      steps = _.filter(steps, (s) => s.id !== stepData.id)
    }
  }
  this.removeStudyNode = (studyNodeData) => {
    const studyNode = studyNodes.find((s) => s.id === studyNodeData.id)
    if (studyNode) {
      if (studyNode.element.classList.contains('selected')) {
        commit('selectElement', { elType: null, elId: null })
      }
      studyNode.shape.remove()
      studyNodes = _.filter(studyNodes, (s) => s.id !== studyNodeData.id)
    }
  }
  this.removeAllStepsLinks = () => {
    for (const link of stepLinks) {
      link.remove()
    }
    stepLinks = []
  }

  this.updateStepPosition = (stepData) => {
    const step = steps.find((s) => s.id === stepData.id)
    if (step) {
      step.shape.position(stepData.rootNode.diagramProperties.x, stepData.rootNode.diagramProperties.y)
    }
  }
  this.updateStudyNodePosition = (studyNodeData) => {
    const studyNode = studyNodes.find((s) => s.id === studyNodeData.id)
    if (studyNode) {
      studyNode.shape.position(studyNodeData.diagramProperties.x, studyNodeData.diagramProperties.y)
    }
  }

  this.refreshStudyNode = (studyNodeData) => {
    const studyNode = studyNodes.find((s) => s.id === studyNodeData.id)
    if (studyNode) {
      studyNode.shape.resize(studyNode.element.clientWidth, studyNode.element.clientHeight)
    }
  }

  this.reset = () => {
    steps = []
    studyNodes = []
    stepLinks = []
    selectedCell = null
    graph.clear()
  }

  this.setReadonly = (isReadonly) => {
    readonly = isReadonly
  }
}

export { Diagram as default }
