/**
 * utils function to deal with search data structure
 * search includes criterion, node and variable
 */
import { Criterion, OperatorEnum, SearchOperatorEnum } from '@/store/modules/search/types'
import {  Variable } from '@/variables/types'

const globalConditionGroupKey = 'globalGroup'
const keyPrefix = '_'
const conditionKeyPrefix = 'condition_'

const emptyVariable: Variable = {
  id: null,
  name: null,
  hash: null,
  searchOperators: SearchOperatorEnum.AND_OR,
  conditionGroups: [],
  globalConditionGroup: {
    conditionList: [],
    id: globalConditionGroupKey,
  },
  aggregateCondition: null,
  modifiers: null,
  user: { id: null },
}

const emptySearch: Criterion = {
  id: null,
  name: null,
  hash: null,
  conditionGroups: [],
  searchOperators: SearchOperatorEnum.AND_OR,
  globalConditionGroup: {
    conditionList: [],
    id: globalConditionGroupKey,
  },
  snomedConceptId: null,
  snomedConceptTerm: null,
  user: { id: null },
}

const emptyConditionGroup = {
  conditionList: [],
  id: null,
}

export function getDisplayableOperator(searchOperators: string, inGroups = false): string {
  return ((!inGroups && searchOperators === SearchOperatorEnum.AND_OR) || (inGroups && searchOperators !== SearchOperatorEnum.AND_OR)) ? OperatorEnum.AND : OperatorEnum.OR
}

export {
  keyPrefix,
  conditionKeyPrefix,
  emptySearch,
  emptyConditionGroup,
  emptyVariable,
}
