<script>
import { InputAutoCompletion } from '@sancare/ui-frontend-commons'
import {
  useAutoCompletionDebouncedWatcher
} from '@sancare/ui-frontend-commons/src/misc/composables/useAutoCompletionDebouncedWatcher'
import { capitalize } from '@sancare/ui-frontend-commons/src/misc/string-utils'
import { ref, watchEffect } from 'vue'
import Slider from 'vue-slider-component'
import { mapState } from 'vuex'

const OFFSET_LABRESULT_VALUE_RANGE = 10

export default {
  components: {
    'vue-slider': Slider,
    'input-auto-completion': InputAutoCompletion,
  },
  props: {
    filterData: { type: Object, required: true },
  },
  emits: ['add-filter'],
  data() {
    const subFiltersValues = {}

    if (this.filterData.subFilters) {
      this.filterData.subFilters.forEach((filter) => {
        if (filter.default) {
          subFiltersValues[filter.name] = filter.default
        } else {
          subFiltersValues[filter.name] = filter.options ? filter.options[0] : ''
        }
      })
    }

    return {
      showCompletion: false,
      subFiltersValues: subFiltersValues,
      selectedItem: null,
      typedInput: '',
      inputMode: 'slider',
      labResultValue: [0, 0],
      categoricalLabResultValue: '',
    }
  },
  computed: {
    sliderValue: {
      set(value) {
        this.labResultValue = value
      },
      get()  {
        const rawMin = parseFloat(this.selectedItem.min)
        const rawMax = parseFloat(this.selectedItem.max)
        let min = rawMin - OFFSET_LABRESULT_VALUE_RANGE * rawMin / 100
        let max = rawMax + OFFSET_LABRESULT_VALUE_RANGE * rawMax / 100

        min = parseInt(min + 0.5)
        max = parseInt(max + 0.5)

        min -= min % 10
        max += 10 - (max % 10)

        if (min < 0 && rawMin >= 0) {
          min = 0
        }

        const diff = max - min
        let step = 1

        if (diff > 1000) {
          step = 50
        } else if (diff > 100) {
          step = 5
        } else if (diff < 1 && diff > 0.1) {
          step = 0.05
        } else if (diff < 1 && diff > 0.01) {
          step = 0.005
        }

        let i = min
        const list = []
        if (min !== 0 && rawMin >= 0) {
          list.push(`<${min}`)
        }
        while (i < max) {
          list.push(i)
          i+= step
        }
        list.push(max)
        list.push(`>${max}`)


        if (min !== 0 && rawMin >= 0) {
          return [`<${min}`, `>${max}`]
        } else {
          return [min, `>${max}`]
        }
      }
    },
    range() {
      const rawMin = parseFloat(this.selectedItem.min)
      const rawMax = parseFloat(this.selectedItem.max)
      let min = rawMin - OFFSET_LABRESULT_VALUE_RANGE * rawMin / 100
      let max = rawMax + OFFSET_LABRESULT_VALUE_RANGE * rawMax / 100

      min = parseInt(min + 0.5)
      max = parseInt(max + 0.5)

      min -= min % 10
      max += 10 - (max % 10)

      if (min < 0 && rawMin >= 0) {
        min = 0
      }

      const diff = max - min
      let step = 1

      if (diff > 1000) {
        step = 50
      } else if (diff > 100) {
        step = 5
      } else if (diff < 1 && diff > 0.1) {
        step = 0.05
      } else if (diff < 1 && diff > 0.01) {
        step = 0.005
      }

      let i = min
      const list = []
      if (min !== 0 && rawMin >= 0) {
        list.push(`<${min}`)
      }
      while (i < max) {
        list.push(i)
        i+= step
      }
      list.push(max)
      list.push(`>${max}`)


      if (min !== 0 && rawMin >= 0) {
        return { min: `<${min}`, max: `>${max}`, list }
      } else {
        return { min, max: `>${max}`, list }
      }
    },
    ...mapState({
      completionItems: (state) => state.medicalUnitFilter.completionItems,
      biologyValues: (state) => state.medicalUnitFilter.biologyValues,
      completionRequest: (state) => state.medicalUnitFilter.completionRequest,
    }),
  },
  watch: {
    filterType() {
      this.$store.commit('medicalUnitFilter/clearCompletionItems')
      this.$store.commit('medicalUnitFilter/clearBiologyValues')
      this.typedInput = ''
    },
  },
  mounted() {
    const typedInputRef = ref(this.typedInput)
    const completionItemsRef = ref(this.completionItems)

    useAutoCompletionDebouncedWatcher(
      typedInputRef,
      completionItemsRef,
      (search, signal) => this.fetchCompletionItems(search, signal),
      () => this.clearCompletionItems()
    )

    watchEffect(() => {
      typedInputRef.value = this.typedInput
    })
  },
  methods: {
    selectItem(completionItem) {
      this.$store.commit('medicalUnitFilter/clearBiologyValues')
      this.selectedItem = completionItem
      this.typedInput = completionItem.title
      if (completionItem.type === 'lab_result') {
        this.labResultValue = [completionItem.min, completionItem.max]
      } else {
        this.$store.dispatch('medicalUnitFilter/getBiologyValues', {
          url: '/api/stay-criterion-condition/list',
          urlParams: {
            title:  completionItem.title
          }
        })
        this.categoricalLabResultValue = ''
      }
      this.closeCompletion()
    },
    addFilter() {
      const name = this.filterData.name
      let type = 'biologyResult'
      let value = this.selectedItem.code

      type = type.replace('{filterType}', capitalize(this.filterData.name))
      if (this.filterData.subFilters) {
        this.filterData.subFilters.forEach((filter) => {
          type = type.replace(`{${filter.name}}`, capitalize(this.subFiltersValues[filter.name]))
        })
      }
      type = type.charAt(0).toLowerCase() + type.slice(1)

      if (this.selectedItem.type === 'lab_result') {
        value += `\x1F${this.labResultValue[0]}\x1F${this.labResultValue[1]}`
      } else {
        value += `\x1F${this.categoricalLabResultValue}`
      }

      this.$emit('add-filter', { name, type, value })
    },
    openCompletion() {
      this.showCompletion = true
    },
    closeCompletion() {
      this.showCompletion = false
    },
    toggleInputMode() {
      this.inputMode = this.inputMode === 'slider' ? 'manual' : 'slider'
    },
    fetchCompletionItems(search, signal) {
      this.$store.dispatch('medicalUnitFilter/getCompletionItems', {
        url: '/api/stay-criterion-condition/completion',
        urlParams: {
          type: this.filterData.name.toLowerCase(),
          search,
        },
        signal,
      })
    },
    clearCompletionItems() {
      this.$store.commit('medicalUnitFilter/clearCompletionItems')
      this.typedInput = ''
    },
  },
}
</script>

<template>
  <div>
    <div class="row">
      <div
        v-on-click-outside="closeCompletion"
        class="col-12 autocompletion-container"
        @keydown.esc="closeCompletion"
      >
        <input
          v-model="typedInput"
          class="form-control"
          @click="openCompletion"
        >
        <input-auto-completion
          v-show="showCompletion && typedInput !== ''"
          :completion-list="completionItems"
          :is-loading="completionRequest.fetching"
          :title-prop="'code'"
          :description-prop="'title'"
          @select-item="selectItem"
        />
      </div>
      <div
        v-if="selectedItem && selectedItem.type === 'lab_result'"
        class="col-6 row align-items-center"
      >
        <div class="col">
          <vue-slider
            v-if="inputMode === 'slider'"
            v-model="sliderValue"
            :v-data="range.list"
            :tooltip="'always'"
            range
          />
          <div
            v-else
            class="row no-gutters align-items-center"
          >
            <div class="col-2 text-center">
              de
            </div>
            <div class="col">
              <input
                v-model="labResultValue[0]"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-2 text-center">
              à
            </div>
            <div class="col">
              <input
                v-model="labResultValue[1]"
                type="text"
                class="col form-control"
              >
            </div>
          </div>
        </div>
        <div class="col-auto">
          <button
            class="btn btn-primary"
            type="button"
            @click="addFilter"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        v-if="selectedItem && selectedItem.type === 'categorical_lab_result'"
        class="col-5 input-group"
      >
        <select
          v-model="categoricalLabResultValue"
          class="custom-select"
        >
          <option
            value=""
            disabled
          >
            ---
          </option>
          <option
            v-for="(value, idx) in biologyValues"
            :key="'bio_value_'+idx"
            :value="value"
          >
            {{ value }}
          </option>
        </select>
        <div class="input-group-append">
          <button
            class="btn btn-primary"
            type="button"
            @click="addFilter"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="selectedItem && selectedItem.type === 'lab_result'"
      class="row"
    >
      <div class="col">
        <span
          class="btn btn-link"
          @click="toggleInputMode()"
        >
          {{ inputMode === 'slider' ? 'Mode manuel' : 'Mode normal' }}
        </span>
      </div>
    </div>
  </div>
</template>
