import _ from 'lodash'

import { Stay } from '@/store/modules/stay/types'
import { StudyNode } from '@/store/modules/study/types'
import { StudyNodeMatches, TextWorkerConditions, TextWorkerDrug, TextWorkerKeyword } from '@/text-processing/types'

interface TextWorkerResponse {
  studyNodeMatches: StudyNodeMatches
  variableMatches: StudyNodeMatches
  stays: Stay[]
}

let runningWorker = null

export function extractTextWorkerConditions(studyNodes: StudyNode[]): TextWorkerConditions {
  const textWorkerConditions = {
    drugs: [],
    keywords: [],
  }

  for(const studyNode of studyNodes) {
    extractTextWorkerConditionsRecursive(studyNode, [], textWorkerConditions)
  }

  // handle duplicates drugs
  textWorkerConditions.drugs = textWorkerConditions.drugs.reduce((result: TextWorkerDrug[], value: TextWorkerDrug) => {
    const r = result.find((drug) => {
      return drug.value === value.value
    })
    if (r) {
      r.studyNodeIds.push(value.studyNodeIds[0])
    } else {
      result.push(value)
    }
    return result
  }, [])

  // handle duplicates keywords
  textWorkerConditions.keywords = textWorkerConditions.keywords.reduce((result: TextWorkerKeyword[], value: TextWorkerKeyword) => {
    const r = result.find((kw) => {
      return kw.value === value.value && _.isEqual(kw.softAbsentContents, value.softAbsentContents) && kw.documentType === value.documentType
    })
    if (r) {
      r.studyNodeIds.push(value.studyNodeIds[0])
    } else {
      result.push(value)
    }
    return result
  }, [])

  return textWorkerConditions
}
function extractTextWorkerConditionsRecursive(studyNode: StudyNode, softAbsentContents: string[], textWorkerConditions: TextWorkerConditions): void {
  const localSoftAbsentContents = _.uniq(_.concat(
    softAbsentContents,
    studyNode.conditionList.filter((c) => c.type === 'softAbsentContent').map((c) => c.value)
  )) as string[]
  const keywords = studyNode.conditionList.filter((c) => c.type.startsWith('presentContent')).map((c) => {
    return {
      value: c.value,
      matching: false,
      documentType: c.type.split('presentContent__')[1] || null,
      softAbsentContents: localSoftAbsentContents,
      studyNodeIds: [studyNode.id]
    }
  })
  textWorkerConditions.keywords = textWorkerConditions.keywords.concat(keywords)

  const drugs = studyNode.conditionList.filter((c) => c.type === 'presentDrug').map((c) => {
    return {
      value: c.value,
      matching: false,
      studyNodeIds: [studyNode.id]
    }
  })
  textWorkerConditions.drugs = textWorkerConditions.drugs.concat(drugs)

  for(const child of studyNode.children) {
    extractTextWorkerConditionsRecursive(child, localSoftAbsentContents, textWorkerConditions)
  }
}

function terminateWorker() {
  if (runningWorker !== null) {
    runningWorker.terminate()
    runningWorker = null
  }
}

function asyncPatientParsing(stays: Stay[], studyNodes: StudyNode[], variables: StudyNode[]): Promise<TextWorkerResponse> {
  terminateWorker()
  const promise = new Promise((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    runningWorker = new Worker(new URL('../text-worker.js', import.meta.url))

    runningWorker.onmessage = (e) => {
      resolve(e.data)
      runningWorker.terminate()
      runningWorker = null
    }

    runningWorker.onerror = (e) => {
      runningWorker.terminate()
      runningWorker = null
      // eslint-disable-next-line no-console
      console.error(e)
      reject()
    }

    runningWorker.postMessage({ stays: stays, conditions: extractTextWorkerConditions(studyNodes), variables: extractTextWorkerConditions(variables) })
  }) as Promise<TextWorkerResponse>

  // caution: sometimes text-worker crash silently. We must catch manually the error in order to show it.
  promise.catch((err) => {
    // eslint-disable-next-line
    console.error(err)
  })

  return promise
}

export {
  asyncPatientParsing,
  terminateWorker,
}
