import { HealthDataType } from '@sancare/ui-frontend-commons/src/types/health-data'

export function getStayDocPropertyFromDocType(docType: HealthDataType): string {
  switch (docType) {
    case HealthDataType.REPORT:
      return 'reports'
    case HealthDataType.CAT_LAB_RESULT:
      return 'categoricalLabResults'
    case HealthDataType.DRUG_EVENT:
      return 'drugEvents'
    case HealthDataType.CONSTANT:
      return 'constants'
    case HealthDataType.LAB_RESULT:
      return 'labResults'
    case HealthDataType.TEXTUAL_HEALTH_ENTRY:
      return 'textualHealthEntries'
    default:
      return null
  }
}
