import { ApiRequest } from '@sancare/ui-frontend-commons/src/misc/api-request'

import { JobState } from './types'

const baseUrl = '/api/job'

const state = new JobState()
state.requests = {
  createStudyComputeJob: new ApiRequest('createStudyComputeJob', 'POST', `${baseUrl}/study/{studyId}/compute`),
  fetchJobs: new ApiRequest('fetchJobs', 'GET', `${baseUrl}/`)
}

export { state }
