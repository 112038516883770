import _ from 'lodash'

import { PatientListColumn, PatientListColumnEnum } from '@/store/modules/patient/types'

export const patientIdColumnName = 'ipp'

export const patientIdColumn = {
  type: PatientListColumnEnum.SYSTEM,
  name: patientIdColumnName,
  label: 'IPP',
  isDefault: true,
  invertedOrder: false,
}

export const criterionListColumns = {
  [patientIdColumnName]: patientIdColumn,
  birthYear: {
    type: PatientListColumnEnum.SYSTEM,
    name: 'birthYear',
    label: 'Année de naissance',
    isDefault: true,
    invertedOrder: false,
  },
  gender: {
    type: PatientListColumnEnum.SYSTEM,
    name: 'genderLabel',
    label: 'Sexe',
    isDefault: true,
    invertedOrder: false,
  },
  deceaseDate: {
    type: PatientListColumnEnum.SYSTEM,
    name: 'deceaseDate',
    label: 'Date de décès',
    isDefault: true,
    invertedOrder: false,
  },
} as { [key: string]: PatientListColumn }

export const studyListColumns = {
  [patientIdColumnName]: patientIdColumn,
  birthYear: {
    type: PatientListColumnEnum.SYSTEM,
    name: 'birthYear',
    label: 'Année de naissance',
    isDefault: true,
    invertedOrder: false,
  },
  gender: {
    type: PatientListColumnEnum.SYSTEM,
    name: 'genderLabel',
    label: 'Sexe',
    isDefault: true,
    invertedOrder: false,
  },
  deceaseDate: {
    type: PatientListColumnEnum.SYSTEM,
    name: 'deceaseDate',
    label: 'Date de décès',
    isDefault: true,
    invertedOrder: false,
  },
  feedback: {
    type: PatientListColumnEnum.SYSTEM,
    name: 'feedback',
    label: 'Feedback',
    isDefault: true,
    invertedOrder: false,
    sortDisabled: true,
  },
} as { [key: string]: PatientListColumn }

export const variableListColumns = {
  [patientIdColumnName]: patientIdColumn,
  distribution: {
    type: PatientListColumnEnum.SYSTEM,
    name: 'matchConditions',
    label: 'Répond aux conditions',
    isDefault: true,
    invertedOrder: false,
    sortDisabled: true,
  },
} as { [key: string]: PatientListColumn }

export const aggregatedVariableListColumns = {
  [patientIdColumnName]: patientIdColumn,
  funcResult: {
    type: PatientListColumnEnum.SYSTEM,
    name: 'aggregatedConditionResult',
    label: 'Résultat de fonction',
    isDefault: true,
    invertedOrder: false,
    sortDisabled: true,
  }
} as { [key: string]: PatientListColumn }

export type UserColumnsSettings = {
  defaultCriterion: { [key: string]: PatientListColumn }
  defaultStudy: { [key: string]: PatientListColumn }
  variable: { [key: string]: PatientListColumn }
  aggregatedVariable: { [key: string]: PatientListColumn }
  studies: { [key: string]: { [key: string]: PatientListColumn } }
}

export const emptyStoredUserColumnsSettings = () => ({
  defaultCriterion: _.pickBy(criterionListColumns, (column) => column.isDefault),
  defaultStudy: _.pickBy(studyListColumns, (column) => column.isDefault),
  variable: _.pickBy(variableListColumns, (column) => column.isDefault),
  aggregatedVariable: _.pickBy(aggregatedVariableListColumns, (column) => column.isDefault),
  studies: {},
})
